import { createContext } from 'react';
export interface InstagramContext {
	handleInstagramPost?: (
		profile: any,
		encodedCaption: string,
		imgUrl: string,
		isScheduled: boolean,
		utcTime: string
	) => void;
	instagramPages: any;
}

export const InstagramContext = createContext<InstagramContext>(null);
