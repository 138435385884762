import { useState, useEffect } from 'react';
import { loadItems } from '../utils';
import { ARRAY_SIZE } from '../types';

export function useLoadItems(posts) {
	const [loading, setLoading] = useState(false);
	const [items, setItems] = useState([]);
	const [startSlice, setStartSlice] = useState(0);
	const [endSlice, setEndSlice] = useState(ARRAY_SIZE);
	const [hasNextPage, setHasNextPage] = useState<boolean>(true);
	const [error, setError] = useState<Error>();

	useEffect(() => {
		setItems([]);
		setStartSlice(0);
		setEndSlice(ARRAY_SIZE);
		setHasNextPage(true);
	}, [posts]);

	async function loadMore() {
		setLoading(true);
		try {
			const { data, hasNextPage: newHasNextPage } = loadItems(
				items.length,
				posts,
				startSlice,
				endSlice
			);
			setItems((current) => [...current, ...data]);
			setStartSlice((current) => (current += ARRAY_SIZE));
			setEndSlice((current) => (current += ARRAY_SIZE));
			setHasNextPage(newHasNextPage);
		} catch (err) {
			setError(err);
		} finally {
			setLoading(false);
		}
	}

	return { loading, items, hasNextPage, error, loadMore };
}
