import { prepareCaption, prepareHashtags } from '../utils';

export const useCustomCaptions = (
	userCaptionSettings: any,
	postCaption: string,
	postHashtags: string
) => {
	const preparedCaption = prepareCaption(userCaptionSettings, postCaption);
	const preparedHashtags = prepareHashtags(userCaptionSettings, postHashtags);

	return { preparedCaption, preparedHashtags };
};
