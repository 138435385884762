import { API } from '../../lib/api/api';
import axios from 'axios';

export const getCustomerPaymentMethods = async (customerId: string) => {
	try {
		const res = await axios.get(
			`${API}user/stripe/getPaymentMethods/?customerId=${customerId}`
		);
		return res;
	} catch (e) {
		return {
			data: e,
			error: e,
			status: 'error',
			statusText:
				'There has been an error retrieving your Copy and Post subscription details. Please contact support.'
		};
	}
};
