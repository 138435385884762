import React, { useState } from 'react';
import { MediaUploadContext } from './MediaUploadContext';

export const MediaUploadProvider: React.FC = ({ children }) => {
	const [isUploadingMedia, setIsUploadingMedia] = useState(false);
	const [customMediaUrl, setCustomMediaUrl] = useState(null);

	return (
		<MediaUploadContext.Provider
			value={{
				isUploadingMedia,
				setIsUploadingMedia,
				setCustomMediaUrl,
				customMediaUrl
			}}
		>
			{children}
		</MediaUploadContext.Provider>
	);
};
