import React, { useEffect, useState } from 'react';
import { useUserStore } from 'src/packages/state';
import { getUserPermissions } from 'src/packages/services';
import { FacebookPermissionsContext } from './FacebookPermissionsContext';

export const FacebookPermissionsProvider: React.FC = ({ children }) => {
	const { connectedUserProfiles } = useUserStore();
	const [declinedPermissions, setDeclinedPermissions] = useState([]);
	const [isMissingFBPermissions, setIsMissingFBPermissions] = useState(false);
	const [isMissingIGPermissions, setIsMissingIGPermissions] = useState(false);

	const fetchUserPermissions = async (facebookAccessToken: string) => {
		const fetchedUserPermissions = await getUserPermissions(facebookAccessToken);
		if (fetchedUserPermissions.status === 200) {
			const declinedPermissions = fetchedUserPermissions.data.data.filter(
				(perm) => {
					return perm.status === 'declined';
				}
			);
			const missingInstagramPermissions = declinedPermissions.some((perm) =>
				perm.permission.includes('instagram')
			);
			const missingFacebookPermissions = declinedPermissions.some((perm) =>
				perm.permission.includes('page')
			);
			setIsMissingFBPermissions(missingFacebookPermissions);
			setIsMissingIGPermissions(missingInstagramPermissions);
			setDeclinedPermissions(declinedPermissions);
		}
	};

	useEffect(() => {
		if (connectedUserProfiles?.facebook) {
			fetchUserPermissions(connectedUserProfiles.facebook.access_token);
		}
	}, [connectedUserProfiles]);

	return (
		<FacebookPermissionsContext.Provider
			value={{
				declinedPermissions,
				isMissingFBPermissions,
				isMissingIGPermissions
			}}
		>
			{children}
		</FacebookPermissionsContext.Provider>
	);
};
