import { API } from '../../lib/api/api';
import axios from 'axios';

export const generateCaptionVariations = async (captionOrHashTags: string) => {
	try {
		return await axios.post(`${API}captionGenerator`, {
			input: captionOrHashTags
		});
	} catch (e) {
		return {
			data: e,
			status: 'error',
			statusText: 'There was a problem regenerating your caption.'
		};
	}
};
