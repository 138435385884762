// Import required AWS SDK clients and command for Node.js
import { S3Client } from '@aws-sdk/client-s3';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-providers';
import { awsConfig } from 'src/config';

export const REGION = awsConfig.region;
export const BUCKET = awsConfig.s3BucketName;

export const s3Client = new S3Client({
	region: REGION,
	credentials: fromCognitoIdentityPool({
		clientConfig: { region: REGION }, // Configure the underlying CognitoIdentityClient.
		identityPoolId: awsConfig.identityPoolId
	})
});
