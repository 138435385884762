import { createContext } from 'react';

type Permission = {
	permission: string;
	status: string;
};

export interface FacebookPermissionsContext {
	isMissingFBPermissions?: boolean;
	isMissingIGPermissions?: boolean;
	declinedPermissions?: Permission[];
}

export const FacebookPermissionsContext =
	createContext<FacebookPermissionsContext>(null);
