import React, { useState, useContext } from 'react';
import { useSnackbar } from 'notistack';
import {
	schedulePost,
	generateInstagramContainerId,
	postToInstagram
} from '../../services';
import { InstagramContext } from './InstagramContext';
import { CalendarContext } from 'src/packages/calendar/context';
import { useUserStore } from 'src/packages/state';
import { useAuth0 } from '@auth0/auth0-react';

export const InstagramProvider: React.FC = ({ children }) => {
	const { enqueueSnackbar } = useSnackbar();
	const { fetchAllScheduledPosts } = useContext(CalendarContext);
	const { userDetails, instagramPages, connectedUserProfiles } = useUserStore();
	const { user } = useAuth0();

	const handleResponse = (res) => {
		if (res.status === 200) {
			enqueueSnackbar('Successful Instagram post!', {
				anchorOrigin: {
					horizontal: 'right',
					vertical: 'top'
				},
				variant: 'success'
			});
		}
		if (res.status === 'error') {
			enqueueSnackbar(res.statusText, {
				anchorOrigin: {
					horizontal: 'right',
					vertical: 'top'
				},
				variant: 'error'
			});
		}
	};

	const handleScheduledInstagramPost = async (
		instagramAccountId,
		pageAccessToken,
		userAccessToken,
		utcTime,
		userId,
		imgUrl,
		encodedCaption,
		profileUsername
	) => {
		const res = await schedulePost(
			instagramAccountId,
			pageAccessToken,
			userAccessToken,
			utcTime,
			userId,
			imgUrl,
			encodedCaption,
			profileUsername
		);
		// refetch scheduled posts for the Calendar view
		fetchAllScheduledPosts(userDetails);
		return handleResponse(res);
	};

	const handleInstagramPost = async (
		profile,
		encodedCaption: string,
		imgUrl: string,
		isScheduled: boolean,
		utcTime: string
	) => {
		const isGif = imgUrl.split('.').pop() === 'gif';
		const { access_token: pageAccessToken, instagramAccountId } = profile;
		const userAccessToken = connectedUserProfiles?.facebook?.access_token;

		if (isGif) {
			enqueueSnackbar('Posting GIFs to Instagram is not supported at this time.', {
				anchorOrigin: {
					horizontal: 'right',
					vertical: 'top'
				},
				variant: 'error'
			});
			return;
		}

		if (isScheduled) {
			await handleScheduledInstagramPost(
				instagramAccountId,
				pageAccessToken,
				userAccessToken,
				utcTime,
				user.sub,
				imgUrl,
				encodedCaption,
				profile.username
			);
			return;
		}

		// container id generation requires the PAGE access token
		const containerId = await generateInstagramContainerId(
			instagramAccountId,
			pageAccessToken,
			imgUrl,
			encodedCaption
		);

		// the post media endpoint requires the USER access token
		const res = await postToInstagram(
			instagramAccountId,
			userAccessToken,
			containerId,
			isScheduled,
			utcTime,
			user.sub,
			imgUrl,
			encodedCaption,
			profile.name
		);
		return handleResponse(res);
	};

	return (
		<InstagramContext.Provider value={{ handleInstagramPost, instagramPages }}>
			{children}
		</InstagramContext.Provider>
	);
};
