import axios from 'axios';
import { API } from '../../../lib/api/api';

export const postToLinkedIn = async (
	personUrn: string,
	accessToken: string,
	shareCaption: string,
	imageUrl: string,
	imageTitle: string,
	imageCaption: string,
	isScheduled?: boolean,
	utcTime?: string,
	auth0userId?: string,
	profileName?: string
) => {
	try {
		return await axios.get(
			`${API}linkedin/share/${personUrn}?personUrn=${personUrn}&accessToken=${accessToken}&shareCaption=${shareCaption}&imageUrl=${imageUrl}&imageTitle=${imageTitle}&imageCaption=${imageCaption}&utcTime=${utcTime}&auth0userId=${auth0userId}&isScheduled=${isScheduled}&profileName=${profileName}`
		);
	} catch (e) {
		return {
			error: e,
			status: 'error',
			statusText:
				'Something went wrong. Your post was not fulfulled. If this issue persists, contact support.',
			data: e
		};
	}
};
