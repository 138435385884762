import React, { useState } from 'react';
import { createStyles, Theme, withStyles, makeStyles } from '@mui/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import LinearProgress from '@material-ui/core/LinearProgress';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import StyleItOptions from './StyleItOptions';
import { useUserStore } from '../../state/store';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Divider } from '@mui/material';

const styles = (theme: Theme) =>
	createStyles({
		root: {
			margin: 0,
			padding: theme.spacing(2)
		},
		closeButton: {
			position: 'absolute',
			right: theme.spacing(1),
			top: theme.spacing(1),
			color: theme.palette.grey[500]
		},
		modal: {
			width: '1000px'
		}
	});

export interface DialogTitleProps extends WithStyles<typeof styles> {
	id: string;
	children: React.ReactNode;
	onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{children}</Typography>
		</MuiDialogTitle>
	);
});

const DialogContent = withStyles((theme: Theme) => ({
	root: {
		padding: theme.spacing(2)
	}
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(1)
	}
}))(MuiDialogActions);

export default function CustomizedDialogs() {
	const [open, setOpen] = React.useState(true);
	const [styleSelection, setStyleSelection] = useState(null);
	const { user } = useAuth0();
	const { setStylePreference, isLoading } = useUserStore();

	const submitPreferences = async () => {
		setStylePreference(user.sub, styleSelection);
		setOpen(false);
	};

	const skipModal = async () => {
		setStylePreference(user.sub, 'none');
		setOpen(false);
	};

	return (
		<div>
			<Dialog
				disableEscapeKeyDown
				aria-labelledby="style-it"
				open={open}
				fullWidth={true}
				maxWidth={false}
				onClose={(event, reason) => {
					return false;
				}}
			>
				<DialogTitle id="style-it-title" onClose={() => false}>
					<Typography textAlign="center" variant="h4" marginBottom="1rem">
						Style It
					</Typography>
					<Divider variant="fullWidth" />
					<Typography textAlign="center" variant="subtitle1" marginTop="1rem">
						A cohesive brand starts with a stylish Content Collection.
					</Typography>
					<Typography textAlign="center" variant="subtitle1">
						All styles will be available for use but your style of choice will be
						pre-selected for you. Easily change within your account settings.
					</Typography>
				</DialogTitle>
				<DialogContent dividers>
					{isLoading && (
						<Box sx={{ mt: 2, mb: 2 }}>
							<LinearProgress />
						</Box>
					)}
					<StyleItOptions
						setStyleSelection={setStyleSelection}
						styleSelection={styleSelection}
					/>
				</DialogContent>
				<DialogActions>
					<Button
						sx={{ fontSize: '1.2rem', marginRight: '0.5rem' }}
						size="large"
						onClick={() => skipModal()}
						color="primary"
						disabled={isLoading}
						variant="outlined"
					>
						Skip this for now
					</Button>
					<Button
						sx={{ fontSize: '1.2rem' }}
						size="large"
						onClick={() => submitPreferences()}
						color="primary"
						variant="outlined"
						disabled={!styleSelection || isLoading}
					>
						Select and continue
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
