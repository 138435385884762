import { convertDateToUnix } from './convertDateToUnix';
import { THUMBNAIL_COLORS } from '../types';

export const augmentLinkedInActions = (linkedinActions, status) => {
	const actions = [];

	linkedinActions?.forEach((action) => {
		const unixTimestamp = convertDateToUnix(action.scheduled_time);
		actions.push({
			id: `${action.id}`,
			description: action.caption,
			allDay: false,
			end: action.scheduled_time,
			start: action.scheduled_time,
			title: action.name,
			platform: action.platform,
			color: THUMBNAIL_COLORS.SUCCESS,
			post: {
				full_picture: action.media_url,
				scheduled_publish_time: unixTimestamp * 1000
			},
			status
		});
	});

	return actions;
};
