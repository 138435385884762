import axios from 'axios';
import { API } from '../../../lib/api/api';

export const schedulePost = async (
	instagramAccountId: string,
	pageAccessToken: string,
	userAccessToken: string,
	utcTime: string,
	auth0userId: string,
	imageUrl: string,
	caption: string,
	profileName: string
) => {
	try {
		const res = await axios.get(
			`${API}instagram/schedulePost?instagramAccountId=${instagramAccountId}&pageAccessToken=${pageAccessToken}&userAccessToken=${userAccessToken}&utcTime=${utcTime}&auth0userId=${auth0userId}&imageUrl=${imageUrl}&caption=${caption}&profileName=${profileName}`
		);
		return res;
	} catch (e) {
		return {
			error: e,
			status: 'error',
			statusText:
				'Oops! Your post was not scheduled. Try again, and please contact support if this issue persists.',
			data: e
		};
	}
};
