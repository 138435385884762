import { createContext } from 'react';

export interface FacebookContext {
	handleFacebookPost?: (
		pageId: string,
		access_token: string,
		encodedCaption: string,
		imgUrl: string
	) => void;
	handleScheduledFacebookPost?: (
		pageId: string,
		access_token: string,
		encodedCaption: string,
		unixTimestamp: string,
		pageName: string,
		imgUrl: string
	) => void;
	facebookPages?: any;
	isPosting?: boolean;
}

export const FacebookContext = createContext<FacebookContext>(null);
