import { gql } from '@apollo/client';

const limitedContentQuery = gql`
	query GetChildCategoriesByParentId($parentId: ID!) {
		category(id: $parentId, idType: SLUG) {
			id
			name
			slug
			children {
				edges {
					node {
						id
						posts(first: 300) {
							edges {
								node {
									id
									featuredPost {
										postimage {
											mediaItemUrl
											title
										}
										showdownloadbutton
									}
									databaseId
									tags {
										nodes {
											name
										}
									}
									categories {
										nodes {
											name
										}
									}
								}
							}
						}
						name
					}
				}
			}
		}
	}
`

const protectedContentQuery = gql`
	query GetChildCategoriesByParentId($parentId: ID!) {
		category(id: $parentId, idType: SLUG) {
			id
			name
			slug
			children {
				edges {
					node {
						id
						posts(first: 300) {
							edges {
								node {
									id
									featuredPost {
										postimage {
											mediaItemUrl
											title
										}
										canvalink
										showdownloadbutton
									}
									databaseId
									tags {
										nodes {
											name
										}
									}
									categories {
										nodes {
											name
										}
									}
								}
							}
						}
						name
					}
				}
			}
		}
	}
`;

export const getSocialBySizePosts = (hasActiveSubscription) => {
    return hasActiveSubscription ? protectedContentQuery : limitedContentQuery;
}