import { useEffect, useState } from 'react';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
	Box,
	Card,
	CardContent,
	Container,
	Divider,
	Link,
	Typography,
	Button,
	FormHelperText
} from '@mui/material';
import Logo from '../../components/Logo';
import { useAuth0 } from '@auth0/auth0-react';
import { auth0Config } from '../../../config';
import { useIsMountedRef } from '../../hooks';
import LoadingScreen from '../../components/LoadingScreen';

const Login: FC = () => {
	const isMountedRef = useIsMountedRef();
	const { loginWithPopup, isLoading, isAuthenticated, loginWithRedirect } =
		useAuth0();
	const [error, setError] = useState<string | null>(null);

	if (!isAuthenticated) {
		loginWithRedirect({ redirectUri: auth0Config.redirectUri });
		return null;
	}

	const handleLogin = async (): Promise<void> => {
		try {
			await loginWithPopup();
		} catch (err) {
			console.error(err);
			if (isMountedRef.current) {
				setError(err.message);
			}
		}
	};

	if (isLoading) {
		return <LoadingScreen />;
	}

	return (
		<>
			<Helmet>
				<title>Login | Copy and Post</title>
			</Helmet>
			<Box
				sx={{
					backgroundColor: 'background.default',
					display: 'flex',
					flexDirection: 'column',
					minHeight: '100vh'
				}}
			>
				<Container maxWidth="sm" sx={{ py: '80px' }}>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							mb: 8
						}}
					>
						<RouterLink to="/">
							<Logo
								sx={{
									height: 40,
									width: 40
								}}
							/>
						</RouterLink>
					</Box>
					<Card>
						<CardContent
							sx={{
								display: 'flex',
								flexDirection: 'column',
								p: 4
							}}
						>
							<Box
								sx={{
									alignItems: 'center',
									display: 'flex',
									justifyContent: 'space-between',
									mb: 3
								}}
							>
								<div>
									<Typography color="textPrimary" gutterBottom variant="h4">
										Log in
									</Typography>
								</div>
							</Box>
							<Box
								sx={{
									flexGrow: 1,
									mt: 3
								}}
							>
								<div>
									{error && (
										<Box sx={{ my: 3 }}>
											<FormHelperText error>{error}</FormHelperText>
										</Box>
									)}
									<Box
										sx={{
											display: 'flex',
											justifyContent: 'center'
										}}
									>
										<Button color="primary" onClick={handleLogin} variant="contained">
											Log In
										</Button>
									</Box>
								</div>
							</Box>
							<Divider sx={{ my: 3 }} />
							<Button color="primary" onClick={handleLogin} variant="contained">
								Create new account
							</Button>
						</CardContent>
					</Card>
				</Container>
			</Box>
		</>
	);
};

export default Login;
