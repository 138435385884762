import { gql } from '@apollo/client';

const limitedContentQuery = gql`
	query GetPostsByCategory($id: ID = "", $categoryName: String = "") {
	category(id: $id, idType: SLUG) {
		id
		posts(first: 300, where: {categoryName: $categoryName}) {
		edges {
			node {
			id
			featuredPost {
				suggestedpostdate
				postimage {
				mediaItemUrl
				title
				}
			}
			databaseId
			categories {
				nodes {
				name
				slug
				}
			}
			tags {
				edges {
				node {
					name
				}
				}
			}
			}
		}
		}
	}
	}
`

const protectedContentQuery = gql`
	query GetPostsByCategory($id: ID = "", $categoryName: String = "") {
		category(id: $id, idType: SLUG) {
			id
			posts(first: 300, where: { categoryName: $categoryName }) {
				edges {
					node {
						id
						featuredPost {
							canvalink
							popularhashtags
							recommendedcaption
							suggestedpostdate
							postimage {
								mediaItemUrl
								title
							}
						}
						databaseId
						categories {
							nodes {
								name
								slug
							}
						}
						tags {
							edges {
								node {
									name
								}
							}
						}
					}
				}
			}
		}
	}
`;

export const getPostsByCategory = (hasActiveSubscription) => {
	return hasActiveSubscription ? protectedContentQuery : limitedContentQuery;
}