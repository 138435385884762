import axios from 'axios';
import { API } from '../../lib/api/api';

export const getAllScheduledPosts = async (auth0UserId: string) => {
	try {
		return await axios.get(
			`${API}calendar/getAllScheduledPosts?auth0UserId=${auth0UserId}`
		);
	} catch (e) {
		return {
			error: e,
			status: 'error',
			statusText: 'There was a problem retrieving your scheduled posts.',
			data: e
		};
	}
};
