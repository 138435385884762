import React, { useEffect } from 'react';
import type { FC } from 'react';
import { useRoutes } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import '../../i18n';
import GlobalStyles from '../components/GlobalStyles';
import RTL from '../components/RTL';
import SettingsDrawer from '../components/SettingsDrawer';
import SplashScreen from '../components/SplashScreen';
import { useAuth0 } from '@auth0/auth0-react';
import useScrollReset from '../hooks/useScrollReset';
import useSettings from '../hooks/useSettings';
import routes from '../../routes';
import { createTheme } from '../theme';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { stripeKeys } from '../../config';
import {
	PaymentMethodDialog,
	SubscriptionModal,
	FreeTrialTeaserDialog,
	RestartSubDialog,
	PaymentPastDueDialog
} from '../paywall';
import { useUserStore } from '../state';

const useStyles = makeStyles(() => ({
	success: {
		backgroundColor: '#1f6a5c !important'
	}
}));

const App: FC = () => {
	const content = useRoutes(routes);
	const { settings } = useSettings();
	const { isLoading } = useAuth0();
	const { userDetails } = useUserStore();
	const classes = useStyles();
	useScrollReset();

	const theme = createTheme({
		direction: settings.direction,
		responsiveFontSizes: settings.responsiveFontSizes,
		roundedCorners: settings.roundedCorners,
		theme: settings.theme
	});

	const stripePromise = loadStripe(stripeKeys.publishableKey);

	return (
		<ThemeProvider theme={theme}>
			<Elements stripe={stripePromise}>
				<RTL direction={settings.direction}>
					<SnackbarProvider
						dense
						maxSnack={3}
						classes={{
							variantSuccess: classes.success
						}}
					>
						<GlobalStyles />
						<SettingsDrawer />
						<PaymentMethodDialog />
						<SubscriptionModal />
						<FreeTrialTeaserDialog />
						<RestartSubDialog />
						<PaymentPastDueDialog />
						{!isLoading ? content : <SplashScreen />}
					</SnackbarProvider>
				</RTL>
			</Elements>
		</ThemeProvider>
	);
};

export default App;
