export const getDaysLeftInTrial = (trialEndUnixTimestamp: number) => {
	const trialEndDate = new Date(trialEndUnixTimestamp * 1000);
	const currentDate = new Date();
	// To calculate the time difference of two dates
	const DifferenceIntTime = trialEndDate.getTime() - currentDate.getTime();

	// To calculate the no. of days between two dates
	const DifferenceInDays = DifferenceIntTime / (1000 * 3600 * 24);

	return Math.floor(DifferenceInDays);
};
