export const ARRAY_SIZE = 9;

export interface Item {
	key: number;
	value: string;
}

export interface Response {
	hasNextPage: boolean;
	data: Item[];
}
