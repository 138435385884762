import React, { useContext } from 'react';
import { useNavigate } from 'react-router';
import { Theme, withStyles } from '@mui/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import { PaywallContext } from '../context/';

const DialogContent = withStyles((theme: Theme) => ({
	root: {
		padding: theme.spacing(2),
		textAlign: 'center'
	}
}))(MuiDialogContent);

export const PaymentMethodDialog = () => {
	const { isPaymentMethodDialogOpen, setIsPaymentMethodDialogOpen } =
		useContext(PaywallContext);
	const navigate = useNavigate();
	return (
		<>
			<Dialog
				disableEscapeKeyDown
				aria-labelledby="payment-dialog"
				open={isPaymentMethodDialogOpen}
				fullWidth={true}
				maxWidth={'sm'}
				onClose={() => {
					setIsPaymentMethodDialogOpen(false);
				}}
			>
				<DialogContent dividers>
					<Typography mb="1rem" textAlign="center" variant="h5">
						You must add a payment method to continue.
					</Typography>
					<Button
						onClick={() => {
							setIsPaymentMethodDialogOpen(false);
							navigate('/dashboard/account/billing');
						}}
						size="large"
						variant="contained"
					>
						Add payment method here
					</Button>
				</DialogContent>
			</Dialog>
		</>
	);
};
