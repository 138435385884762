import React, { useState, useEffect } from 'react';
import { PaywallContext } from './PaywallContext';
import {
	getCustomerPaymentMethods,
	getStripeSubscription,
	getSubscriptionInvoices
} from 'src/packages/services/stripe';
import { useUserStore } from 'src/packages/state';
import { getDaysLeftInTrial } from '../utils/getDaysLeftInTrial';

export const PaywallProvider: React.FC = ({ children }) => {
	const [paymentMethods, setPaymentMethods] = useState([]);
	const [userSubscriptionDetailsCopy, setUserSubscriptionDetails] =
		useState(null);
	const [isLoadingUserSubscriptionDetails, setIsLoadingUserSubscriptionDetails] =
		useState(false);
	const [userSubscriptionStatus, setUserSubscriptionStatus] = useState(null);
	const [willUserSubscriptionCancel, setWillUserSubscriptionCancel] =
		useState(false);
	const [userSubscriptionDetailsError, setUserSubscriptionDetailsError] =
		useState(null);
	const [isPaymentMethodDialogOpen, setIsPaymentMethodDialogOpen] =
		useState(false);
	const [isPaymentPastDueDialogOpen, setIsPaymentPastDueDialogOpen] =
		useState(false);
	const [isRestartSubDialogOpen, setIsRestartSubDialogOpen] = useState(false);
	const [isFreeTrialTeaserDialogOpen, setIsFreeTrialTeaserDialogOpen] =
		useState(false);
	const [freeTrialDays, setFreeTrialDays] = useState(null);
	const [shouldShowPaymentMethodDialog, setShouldShowPaymentMethodDialog] =
		useState(false);
	const [isChoosePlanDialogOpen, setIsChoosePlanDialogOpen] = useState(false);
	const [hasBanner, setHasBanner] = useState(false);
	const { userDetails } = useUserStore();
	// get the days left in the users free trial
	const daysLeftInTrial = getDaysLeftInTrial(
		userSubscriptionDetailsCopy?.trial_end
	);

	const fetchCustomerSubscriptionDetails = async (subscriptionId: string) => {
		setIsLoadingUserSubscriptionDetails(true);
		const fetchedStripeSubscription = await getStripeSubscription(subscriptionId);
		if (fetchedStripeSubscription.status !== 200) {
			setHasBanner(true);
			setIsLoadingUserSubscriptionDetails(false);
			setUserSubscriptionDetailsError(
				'There was a problem retrieving your subscription details. Please contact support'
			);
			console.error(
				'There was a problem retrieving the customer subscription details.'
			);
			return;
		}
		setUserSubscriptionDetails(fetchedStripeSubscription.data);
		setWillUserSubscriptionCancel(
			fetchedStripeSubscription.data.cancel_at_period_end
		);
		setIsLoadingUserSubscriptionDetails(false);
	};

	const fetchSubscriptionInvoices = async (subscriptionId: string) => {
		const res = await getSubscriptionInvoices(subscriptionId);
		setUserSubscriptionStatus({ pastDue: res?.data?.pastDue });
	};

	const fetchCustomerPaymentMethods = async (customerId: string) => {
		const res = await getCustomerPaymentMethods(customerId);
		if (res.status !== 200) {
			console.error(
				'There was a problem retrieving the customer payment methods.'
			);
			return;
		}
		setPaymentMethods(res.data.isMissingPaymentMethod);
	};

	useEffect(() => {
		userSubscriptionDetailsCopy && setFreeTrialDays(daysLeftInTrial);
		daysLeftInTrial && setHasBanner(daysLeftInTrial > 0);
		// if the user's free trial is over, and they have no payment method on file
		// show the payment method dialog
		const hasNoPaymentMethod = paymentMethods && freeTrialDays <= 0;
		setShouldShowPaymentMethodDialog(hasNoPaymentMethod);
	}, [userSubscriptionDetailsCopy, paymentMethods]);

	useEffect(() => {
		if (userDetails?.user_metadata?.stripe) {
			fetchCustomerPaymentMethods(userDetails.user_metadata.stripe.customerId);
			fetchCustomerSubscriptionDetails(
				userDetails.user_metadata.stripe.subscriptionId
			);
			fetchSubscriptionInvoices(userDetails.user_metadata.stripe.subscriptionId);
		}
	}, [userDetails?.user_metadata?.stripe]);

	return (
		<PaywallContext.Provider
			value={{
				fetchCustomerPaymentMethods,
				paymentMethods,
				freeTrialDays,
				hasBanner,
				isPaymentMethodDialogOpen,
				setIsPaymentMethodDialogOpen,
				isChoosePlanDialogOpen,
				setIsChoosePlanDialogOpen,
				userSubscriptionDetailsCopy,
				userSubscriptionDetailsError,
				isLoadingUserSubscriptionDetails,
				userSubscriptionStatus,
				willUserSubscriptionCancel,
				shouldShowPaymentMethodDialog,
				isFreeTrialTeaserDialogOpen,
				setIsFreeTrialTeaserDialogOpen,
				setIsRestartSubDialogOpen,
				isRestartSubDialogOpen,
				isPaymentPastDueDialogOpen,
				setIsPaymentPastDueDialogOpen
			}}
		>
			{children}
		</PaywallContext.Provider>
	);
};
