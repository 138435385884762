import { createContext } from 'react';

interface PaywallContext {
	fetchCustomerPaymentMethods?: (customerId: string) => void;
	paymentMethods?: any[];
	hasBanner?: boolean;
	freeTrialDays?: number;
	isPaymentMethodDialogOpen?: boolean;
	setIsPaymentMethodDialogOpen?: (arg: boolean) => void;
	isPaymentPastDueDialogOpen?: boolean;
	setIsPaymentPastDueDialogOpen?: (arg: boolean) => void;
	shouldShowPaymentMethodDialog?: boolean;
	isChoosePlanDialogOpen: boolean;
	setIsChoosePlanDialogOpen?: (arg: boolean) => void;
	userSubscriptionDetailsCopy?: any;
	userSubscriptionDetailsError?: string;
	isLoadingUserSubscriptionDetails?: boolean;
	willUserSubscriptionCancel?: boolean;
	userSubscriptionStatus?: { pastDue: boolean };
	isFreeTrialTeaserDialogOpen?: boolean;
	setIsFreeTrialTeaserDialogOpen?: (arg: boolean) => void;
	isRestartSubDialogOpen?: boolean;
	setIsRestartSubDialogOpen?: (arg: boolean) => void;
}

export const PaywallContext = createContext<PaywallContext>(null);
