import { createContext } from 'react';

export interface CustomCaptionsContext {
	cleanCaption: (caption: string) => string;
	customizeCaption: (captionSettings: any, recommendedCaption: string) => string;
	setCaptionCopied: (value: string) => any;
	setHashtagsCopied: (value: string) => any;
	setIsCaptionAndTagsCopied: (value: string) => any;
	prepareCaption: (captionSettings: any, caption: string) => any;
	prepareHashtags: (captionSettings: any, hashtags: string) => any;
	regeneratedCaption: string | boolean;
	setRegeneratedCaption: (value: string | boolean) => void;
	noCustomCaptionDetails: boolean;
	useCustomCaptions: any;
}

export const CustomCaptionsContext = createContext<CustomCaptionsContext>(null);
