import { useRef, useState, memo } from 'react';
import type { FC } from 'react';
import {
	ListItemIcon,
	ListItemText,
	Tooltip,
	IconButton,
	Menu,
	MenuItem,
	Link
} from '@mui/material';
import ArchiveIcon from 'src/packages/icons/Archive';
import DocumentTextIcon from 'src/packages/icons/DocumentText';
import DotsHorizontalIcon from 'src/packages/icons/DotsHorizontal';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import DownloadIcon from 'src/packages/icons/Download';
import DuplicateIcon from 'src/packages/icons/Duplicate';

const HelpDocsMenu: FC = (props) => {
	const anchorRef = useRef<HTMLButtonElement | null>(null);
	const [openMenu, setOpenMenu] = useState<boolean>(false);

	const handleMenuOpen = (): void => {
		setOpenMenu(true);
	};

	const handleMenuClose = (): void => {
		setOpenMenu(false);
	};

	return (
		<>
			<Tooltip title="Help docs">
				<IconButton onClick={handleMenuOpen} ref={anchorRef} {...props}>
					<HelpOutlineIcon color="primary" fontSize="medium" />
				</IconButton>
			</Tooltip>
			<Menu
				anchorEl={anchorRef.current}
				anchorOrigin={{
					horizontal: 'left',
					vertical: 'bottom'
				}}
				onClose={handleMenuClose}
				open={openMenu}
				PaperProps={{
					sx: {
						maxWidth: '100%'
					}
				}}
				transformOrigin={{
					horizontal: 'left',
					vertical: 'top'
				}}
			>
				<Link
					href="https://scribehow.com/shared/Get_the_most_out_of_Copy_and_Post__DbgV4OTHRcCjUDF0MXU15A#6854a9d2"
					target={'_blank'}
				>
					<MenuItem>
						<ListItemText primary="Style Your Content Collections" />
					</MenuItem>
				</Link>
				<Link
					href="https://scribehow.com/shared/Get_the_most_out_of_Copy_and_Post__DbgV4OTHRcCjUDF0MXU15A#ceb1aa2a"
					target="_blank"
				>
					<MenuItem>
						<ListItemText primary="Search for Content" />
					</MenuItem>
				</Link>
				<Link
					href="https://scribehow.com/shared/Get_the_most_out_of_Copy_and_Post__DbgV4OTHRcCjUDF0MXU15A#c0d34417"
					target="_blank"
				>
					<MenuItem>
						<ListItemText primary="Captions and Hashtags" />
					</MenuItem>
				</Link>
				<Link
					href="https://scribehow.com/shared/Get_the_most_out_of_Copy_and_Post__DbgV4OTHRcCjUDF0MXU15A#249677ba"
					target="_blank"
				>
					<MenuItem>
						<ListItemText primary="Select Social Profiles and Schedule" />
					</MenuItem>
				</Link>
			</Menu>
		</>
	);
};

export default memo(HelpDocsMenu);
