import { useState, useContext } from 'react';
import { useQuery } from '@apollo/client';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import useSettings from '../../hooks/useSettings';
import { THEMES } from '../../../constants';
import {
	Alert,
	AlertTitle,
	AppBar,
	Box,
	Hidden,
	IconButton,
	Toolbar,
	Switch,
	FormGroup,
	FormControlLabel,
	Button,
	Typography,
	useTheme,
	AppBarProps,
	Link
} from '@mui/material';
import { experimentalStyled, styled } from '@mui/material/styles';
import MenuIcon from '../../icons/Menu';
import AccountPopover from './AccountPopover';
import ContactsPopover from './ContactsPopover';
import ContentSearch from './ContentSearch';
import LanguagePopover from './LanguagePopover';
import Logo from '../../../assets/logoWithIcon.svg';
import LogoDark from '../../../assets/logoWithIconDark.svg';
import NotificationsPopover from './NotificationsPopover';
import HelpDocsMenu from 'src/packages/help-docs-menu/components/HelpDocsMenu';
import { getCustomerNoteLink } from 'src/packages/media-browser/gqlQueries';

interface DashboardNavbarProps extends AppBarProps {
	onSidebarMobileOpen?: () => void;
}

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
	width: 62,
	height: 34,
	padding: 7,
	'& .MuiSwitch-switchBase': {
		margin: 1,
		padding: 0,
		transform: 'translateX(6px)',
		'&.Mui-checked': {
			color: '#fff',
			transform: 'translateX(22px)',
			'& .MuiSwitch-thumb:before': {
				backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
					'#fff'
				)}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`
			},
			'& + .MuiSwitch-track': {
				opacity: 1,
				backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be'
			}
		}
	},
	'& .MuiSwitch-thumb': {
		backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
		width: 32,
		height: 32,
		'&:before': {
			content: "''",
			position: 'absolute',
			width: '100%',
			height: '100%',
			left: 0,
			top: 0,
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
			backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
				'#fff'
			)}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`
		}
	},
	'& .MuiSwitch-track': {
		opacity: 1,
		backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
		borderRadius: 20 / 2
	}
}));

const DashboardNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
	...(theme.palette.mode === 'light' && {
		background: '#e7e7e7',
		backgroundColor: '#e7e7e7',
		boxShadow: 'none',
		color: theme.palette.primary.contrastText
	}),
	...(theme.palette.mode === 'dark' && {
		backgroundColor: theme.palette.background.default,
		borderBottom: `1px solid ${theme.palette.divider}`,
		boxShadow: 'none'
	}),
	zIndex: theme.zIndex.drawer + 100
}));

const getValues = (settings) => ({
	compact: settings.compact,
	direction: settings.direction,
	responsiveFontSizes: settings.responsiveFontSizes,
	roundedCorners: settings.roundedCorners,
	theme: settings.theme
});

const DashboardNavbar: FC<DashboardNavbarProps> = (props) => {
	const { onSidebarMobileOpen, ...other } = props;
	const { settings, saveSettings } = useSettings();
	const [values, setValues] = useState(getValues(settings));
	const theme = useTheme();
	const logo = theme?.palette?.mode === 'light' ? Logo : LogoDark;

	const { data } = useQuery(getCustomerNoteLink, {
		variables: { names: 'Customer Note Link' }
	});
	const customerNoteLink =
		data?.categories?.edges[0]?.node?.posts?.nodes[0]?.customerNoteLink;

	const handleChange = (e) => {
		const value = e.target.checked ? 'DARK' : 'LIGHT';
		const newSettings = {
			...values,
			theme: value
		};
		saveSettings(newSettings);
	};

	const handleSave = (): void => {
		saveSettings(values);
	};

	return (
		<>
			<DashboardNavbarRoot {...other}>
				<Toolbar sx={{ minHeight: 64 }}>
					<Hidden lgUp>
						<IconButton color="inherit" onClick={onSidebarMobileOpen}>
							<MenuIcon fontSize="small" />
						</IconButton>
					</Hidden>
					<Hidden lgDown>
						<RouterLink to="/dashboard">
							<img src={logo} width="300px" />
						</RouterLink>
					</Hidden>
					<Box
						sx={{
							flexGrow: 1,
							ml: 2
						}}
					/>
					{/* <LanguagePopover />
					<Box sx={{ ml: 1 }}>
						<ContentSearch />
					</Box>
					<Box sx={{ ml: 1 }}>
						<ContactsPopover />
					</Box>
					<Box sx={{ ml: 1 }}>
						<NotificationsPopover />
					</Box> */}

					<Box>
						<Link
							href={`${customerNoteLink?.link}`}
							variant="subtitle2"
							underline="hover"
							target="_blank"
						>
							{customerNoteLink?.linktext}
						</Link>
					</Box>
					<Box>
						<FormGroup>
							<FormControlLabel
								labelPlacement="start"
								control={
									<MaterialUISwitch
										checked={settings.theme === 'DARK'}
										onChange={handleChange}
									/>
								}
								label={''}
							/>
						</FormGroup>
					</Box>
					<Box sx={{ ml: 2 }}>
						<AccountPopover />
					</Box>
					<Box sx={{ ml: 2 }}>
						<HelpDocsMenu />
					</Box>
				</Toolbar>
			</DashboardNavbarRoot>
		</>
	);
};

DashboardNavbar.propTypes = {
	onSidebarMobileOpen: PropTypes.func
};

export default DashboardNavbar;
