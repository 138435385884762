import React, { useContext, useEffect, useState } from 'react';
import uniqBy from 'lodash.uniqby';
import { useQuery, useLazyQuery } from '@apollo/client';
import { MediaBrowserContext } from 'src/packages/media-browser';
import { ThisMonthsMediaContext } from './ThisMonthsMediaContext';
import { useUserStore } from 'src/packages/state';

export const ThisMonthsMediaProvider: React.FC = ({ children }) => {
	const {
		queries,
		getLocalStorageFilters,
		localStorageKeys,
		initLocalStorageFilters
	} = useContext(MediaBrowserContext);
	const [categories, setCategories] = useState([]);
	const [posts, setPosts] = useState([]);
	const { stylePreference } = useUserStore();
	const localStorageFilters = getLocalStorageFilters(
		localStorageKeys.thisMonthsMedia
	);

	useEffect(() => {
		if (!localStorageFilters && stylePreference && categories.length > 0) {
			const stylePref =
				stylePreference === 'none'
					? categories[0] && categories[0].name
					: stylePreference;
			const stylePrefSlug =
				stylePreference === 'none' 
					? categories[0]?.slug
					: categories.length > 0 && categories.find((cat) => cat.name === stylePreference).slug;
			initLocalStorageFilters(
				localStorageKeys.thisMonthsMedia,
				JSON.stringify({
					categoryNames: [stylePrefSlug],
					tags: [],
					chips: [stylePref]
				})
			);
			addCategory({
				variables: { id: stylePrefSlug, categoryName: 'this-month-media' }
			});
		}
	}, [localStorageFilters, stylePreference, categories]);

	const removeCategory = (categoryName: string, chips: string[]) => {
		const filteredPosts = posts.filter((post) => {
			const isTagged = post?.node?.tags?.edges?.some((tag) =>
				chips.includes(tag.node.name)
			);
			if (isTagged) {
				return post;
			}
			return post?.node?.categories?.nodes?.every(
				(cat) => cat.name !== categoryName
			);
		});
		setPosts(filteredPosts);
	};

	const removeTaggedPosts = (removedTag: string) => {
		const filteredPosts = posts.filter((post) => {
			return post?.node?.tags?.edges?.every(
				(tag) => tag.node.name.toLowerCase() !== removedTag.toLowerCase()
			);
		});
		setPosts(filteredPosts);
	};

	const handleAddCategory = (queryResult) => {
		const result = queryResult?.category?.posts?.edges;
		if (result) {
			const filteredPosts = uniqBy([...result, ...posts], 'node.databaseId');
			setPosts(filteredPosts);
		}
	};

	const handleInitPosts = (queryResult) => {
		const result = [];
		queryResult?.categories?.nodes?.forEach((node) =>
			result.push(...node.posts.edges)
		);
		const filteredPosts = uniqBy([...result, ...posts].flat(), 'node.databaseId');
		setPosts(filteredPosts);
	};

	const handleTagSearch = (queryResult) => {
		const result = queryResult?.category?.posts?.edges;
		// just return if there are no matches for the search
		if (result?.length === 0) {
			return;
		}
		const newPosts = uniqBy([...result, ...posts], 'node.databaseId');
		setPosts(newPosts);
	};

	const [
		tagSearch,
		{
			called: calledTagSearch,
			loading: fetchingTaggedPosts,
			data: fetchedTaggedPosts
		}
	] = useLazyQuery(queries.GET_POSTS_BY_TAGS_NO_CHILDREN, {
		nextFetchPolicy: 'network-only',
		onCompleted: (data) => {
			handleTagSearch(data);
		}
	});

	const [
		addCategory,
		{
			called: calledCategoryAdd,
			loading: fetchingCategory,
			data: fetchedCategory
		}
	] = useLazyQuery(queries.GET_POSTS_BY_CATEGORY, {
		nextFetchPolicy: 'cache-first',
		onCompleted: (data) => handleAddCategory(data)
	});

	const { data: fetchedCategories, loading: isFetchingCategories } = useQuery(
		queries.GET_CHILD_CATS_BY_PARENT_CAT_SLUG,
		{
			variables: { parentId: 'style-color' },
			nextFetchPolicy: 'cache-first',
			onCompleted: (data) => {
				const augmentedCategories = data?.category?.children?.edges.map((cat) => {
					return cat.node;
				});
				setCategories(augmentedCategories);
			}
		}
	);

	return (
		<ThisMonthsMediaContext.Provider
			value={{
				posts,
				setPosts,
				categories,
				addCategory,
				removeCategory,
				tagSearch,
				removeTaggedPosts,
				handleInitPosts
			}}
		>
			{children}
		</ThisMonthsMediaContext.Provider>
	);
};
