import type { FC } from 'react';
import type { Theme } from '@mui/material';
import { experimentalStyled } from '@material-ui/core/styles';
import type { SxProps } from '@material-ui/system';
import CPIcon from '../../assets/logo-tan.png';

interface LogoProps {
	sx?: SxProps<Theme>;
}

const LogoRoot = experimentalStyled('svg')();

const Logo: FC<LogoProps> = (props) => <img src={CPIcon} />;

export default Logo;
