import 'flatpickr/dist/flatpickr.css';
import 'flatpickr/dist/themes/material_red.css';
import Flatpickr from 'react-flatpickr';
import { TextField } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { DateTimePickerMobile } from './DateTimePickerMobile';
import { convertToUTC } from './utils/convertToUTC';
interface DateTimePickerProps {
	setUtcTime: (time: string) => void;
	setPublishTime: (Date) => void;
	defaultDate?: Date;
	isScheduled?: boolean;
	disableMobile?: boolean;
}

export const DateTimePicker = ({
	isScheduled,
	disableMobile,
	setUtcTime,
	setPublishTime,
	defaultDate
}: DateTimePickerProps) => {

	if (isMobile) {
		return (
			<DateTimePickerMobile isScheduled={isScheduled} setUtcTime={setUtcTime} setPublishTime={setPublishTime} />
		)
	}

	return (
		<Flatpickr
			data-enable-time
			options={{
				defaultDate: defaultDate || new Date(),
				minDate: new Date(),
				maxDate: new Date().setDate(new Date().getDate() + 59),
				enableTime: true,
				dateFormat: 'l, M d, Y - h:i K',
				minuteIncrement: 1
			}}
			onChange={([date]) => {
				const utcTime = date && convertToUTC(date);
				setUtcTime(utcTime);
				setPublishTime(date);
			}}
			render={({ defaultValue, value, ...props }, ref) => {
				return <TextField disabled={!isScheduled} fullWidth inputRef={ref} />;
			}}
		/>
	);
};
