import React, { useState, FC, useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useSnackbar } from 'notistack';
import { auth0Config } from 'src/config';
import { useUserStore } from 'src/packages/state';
import {
	SocialProfilesContext,
	UnlinkAccountPayload
} from './SocialProfilesContext';
import { CustomAuth0Connections } from './types';
import { CalendarContext } from 'src/packages/calendar/context';
import { deleteAllScheduledPosts as deleteAllLinkedInScheduledPosts } from 'src/packages/services/social/linkedin';
import { deleteAllInstagramScheduledPosts } from 'src/packages/services/social/instagram';

export const SocialProfilesProvider: FC = ({ children }) => {
	const [isUnLinkingAccount, setIsUnLinkingAccount] = useState(false);
	const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
	const [selectedProfile, setSelectedProfile] = useState(null);
	const [isLinkedInConfirmModalOpen, setIsLinkedInConfirmModalOpen] =
		useState(false);
	const { fetchAllScheduledPosts } = useContext(CalendarContext);
	const { enqueueSnackbar } = useSnackbar();
	const {
		userDetails: primaryUserDetails,
		getUserDetails,
		deleteFacebookPage,
		deleteInstagramPage
	} = useUserStore();
	const { domain, client_id, redirectUri } = auth0Config;
	const {
		getAccessTokenSilently,
		getIdTokenClaims,
		loginWithPopup,
		loginWithRedirect
	} = useAuth0();

	const authenticateUser = async (connection: string) => {
		await loginWithPopup({
			connection: connection,
			response_type: 'code',
			client_id: client_id,
			redirect_uri: redirectUri
		});
		return await getIdTokenClaims();
	};

	const handleDeleteAllScheduledPosts = async (
		connection: string,
		userId: string
	) => {
		if (connection === CustomAuth0Connections.linkedin) {
			await deleteAllLinkedInScheduledPosts(userId);
			return;
		}
		if (connection === CustomAuth0Connections.facebook) {
			await deleteAllInstagramScheduledPosts(userId);
			return;
		}
	};

	const unlinkSocialProfile = async (
		secondaryIdentity: UnlinkAccountPayload
	) => {
		const { provider, user_id, connection } = secondaryIdentity;
		const accessToken = await getAccessTokenSilently();
		const secondaryId = `${connection}|${user_id}`;
		setIsUnLinkingAccount(true);

		await handleDeleteAllScheduledPosts(connection, primaryUserDetails.user_id);

		// https://auth0.com/docs/manage-users/user-accounts/user-account-linking/unlink-user-accounts
		const res = await fetch(
			`https://${domain}/api/v2/users/${primaryUserDetails.user_id}/identities/${provider}/${secondaryId}`,
			{
				method: 'DELETE',
				headers: {
					Authorization: `Bearer ${accessToken}`
				}
			}
		);

		if (res.status === 200) {
			enqueueSnackbar('Account successfully unlinked.', {
				anchorOrigin: {
					horizontal: 'right',
					vertical: 'top'
				},
				variant: 'success'
			});
			await getAccessTokenSilently();
			await getUserDetails(primaryUserDetails.user_id);
			fetchAllScheduledPosts(primaryUserDetails);
			setIsUnLinkingAccount(false);
		}
	};

	const linkSocialProfile = async (connection: string) => {
		const accessToken = await getAccessTokenSilently();
		const { __raw: targetUserIdToken } = await authenticateUser(connection);

		const res = await fetch(
			`https://${domain}/api/v2/users/${primaryUserDetails.user_id}/identities`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${accessToken}`
				},
				body: JSON.stringify({
					link_with: targetUserIdToken
				})
			}
		);

		if (res.status === 201) {
			await loginWithRedirect({ redirectUri: window.location.href });
			enqueueSnackbar('Account successfully linked.', {
				anchorOrigin: {
					horizontal: 'right',
					vertical: 'top'
				},
				variant: 'success'
			});
		}
	};

	return (
		<SocialProfilesContext.Provider
			value={{
				unlinkSocialProfile,
				isConfirmModalOpen,
				isLinkedInConfirmModalOpen,
				setIsLinkedInConfirmModalOpen,
				setIsConfirmModalOpen,
				isUnLinkingAccount,
				setIsUnLinkingAccount,
				authenticateUser,
				linkSocialProfile,
				deleteFacebookPage,
				deleteInstagramPage,
				selectedProfile,
				setSelectedProfile
			}}
		>
			{children}
		</SocialProfilesContext.Provider>
	);
};
