import axios from 'axios';
import { API } from '../../../lib/api/api';

export const getPages = async (facebookUserId: string, auth0UserId: string) => {
	try {
		return await axios.get(
			`${API}facebook/getPages?facebookUserId=${facebookUserId}&auth0UserId=${auth0UserId}`
		);
	} catch (e) {
		return {
			error: e,
			status: 'error',
			statusText: 'There was a problem retrieving your connected Facebook pages.',
			data: e
		};
	}
};
