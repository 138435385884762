import React, { useState, useEffect, useContext } from 'react';
import type { ChangeEvent, KeyboardEvent } from 'react';
import { useQuery } from '@apollo/client';
import { Box, Button, Card, Chip, Divider, Input } from '@mui/material';
import SearchIcon from 'src/packages/icons/Search';
import { MediaBrowserContext } from 'src/packages/media-browser/context';
import { getDemoLinks } from 'src/packages/media-browser/gqlQueries';
import { useUserStore } from 'src/packages/state';
import { ThisMonthsMediaContext } from '../context';
import MultiSelect from './MultiSelect';
import {
	MonthlyDownloadButton,
	createDownloadOptionsArray
} from '../../common';

const PostBrowseFilter = (props) => {
	const {
		categories,
		addCategory,
		removeCategory,
		tagSearch,
		setPosts,
		removeTaggedPosts
	} = useContext(ThisMonthsMediaContext);
	const { getLocalStorageFilters, localStorageKeys } =
		useContext(MediaBrowserContext);

	const { stylePreference } = useUserStore();
	const [inputValue, setInputValue] = useState<string>('');
	const [chips, setChips] = useState<string[]>([]);
	const localStorageFilters = getLocalStorageFilters(
		localStorageKeys.thisMonthsMedia
	);

	const { data } = useQuery(getDemoLinks, {
		variables: { names: 'DEMO Links' }
	});

	const links = data?.categories?.edges[0]?.node?.posts?.nodes[0]?.demoLinks;

	const matchingStoriesOptions = createDownloadOptionsArray(
		links?.matchingstories
	);
	const monthlyDownloadsOptions = createDownloadOptionsArray(
		links?.monthlydownloads
	);

	useEffect(() => {
		const setStylePref =
			stylePreference === 'none'
				? categories[0] && categories[0]?.name
				: stylePreference;
		setChips(localStorageFilters?.chips || [setStylePref]);
	}, [stylePreference, categories]);

	const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
		setInputValue(event.target.value);
	};

	const handleInputKeyup = (event: KeyboardEvent<HTMLInputElement>): void => {
		if (event.code === 'Enter' && inputValue) {
			if (!chips.includes(inputValue)) {
				tagSearch({
					variables: { parentId: 'this-month-media', tagSlugIn: [inputValue] }
				});
				setChips((prevChips) => [...prevChips, inputValue]);
				setInputValue('');
			}
		}
	};

	const handleChipDelete = (chip: string): void => {
		const isTaggedPost = !categories.some((cat) => cat.name === chip);
		if (isTaggedPost) {
			removeTaggedPosts(chip);
			setChips((prevChips) => prevChips.filter((prevChip) => chip !== prevChip));
			return;
		}
		removeCategory(chip, chips);
		setChips((prevChips) => prevChips.filter((prevChip) => chip !== prevChip));
	};

	const handleMultiSelectChange = (
		value: string[],
		categorySlug: string,
		selectedValue: string
	): void => {
		addCategory({
			variables: { id: categorySlug, categoryName: 'this-month-media' }
		});
		setChips(value);
	};

	return (
		<Card {...props}>
			<Box
				sx={{
					alignItems: 'center',
					display: 'flex',
					p: 2
				}}
			>
				<SearchIcon fontSize="small" />
				<Box
					sx={{
						flexGrow: 1,
						ml: 3
					}}
				>
					<Input
						disableUnderline
						fullWidth
						onChange={handleInputChange}
						onKeyUp={handleInputKeyup}
						placeholder="Search for social - type anything here!"
						value={inputValue}
					/>
				</Box>
			</Box>
			<Divider />
			<Box
				sx={{
					alignItems: 'center',
					display: 'flex',
					flexWrap: 'wrap',
					p: 2
				}}
			>
				{chips.map((chip) => (
					<Chip
						key={chip}
						label={chip}
						onDelete={(): void => handleChipDelete(chip)}
						sx={{ m: 1 }}
						variant="outlined"
					/>
				))}
			</Box>
			<Divider />
			<Box
				sx={{
					alignItems: 'center',
					display: 'flex',
					flexWrap: 'wrap',
					p: 1
				}}
			>
				{categories.map((category) => (
					<MultiSelect
						key={category.id}
						label={category.name}
						slug={category.slug}
						onChange={handleMultiSelectChange}
						value={chips}
					/>
				))}
				<Box sx={{ flexGrow: 1 }} />
				<Box display={'flex'} gap={'1rem'}>
					<Button
						variant={'contained'}
						size={'medium'}
						onClick={() => {
							setPosts([]);
							setChips([]);
						}}
					>
						Clear All Categories
					</Button>
				</Box>
			</Box>
		</Card>
	);
};

export default PostBrowseFilter;
