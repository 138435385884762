import React, { useState, useContext } from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@mui/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import { useAuth0 } from '@auth0/auth0-react';
import PricingOptions from './PricingOptions';
import {
	createCheckoutSession,
	updateExistingCustomer
} from 'src/packages/services/stripe';
import { useStripe } from '@stripe/react-stripe-js';
import { useSnackbar } from 'notistack';
import { useSubscriptionPlanDetails } from 'src/packages/hooks';
import { PaywallContext } from '../context';

const styles = (theme: Theme) =>
	createStyles({
		root: {
			margin: 0,
			padding: theme.spacing(2)
		},
		closeButton: {
			position: 'absolute',
			right: theme.spacing(1),
			top: theme.spacing(1),
			color: theme.palette.grey[500]
		},
		modal: {
			width: '1000px'
		}
	});

interface DialogTitleProps extends WithStyles<typeof styles> {
	id: string;
	children: React.ReactNode;
	onClose: () => void;
}

const DialogContent = withStyles((theme: Theme) => ({
	root: {
		padding: theme.spacing(2)
	}
}))(MuiDialogContent);

export const RestartSubDialog = () => {
	const { setIsRestartSubDialogOpen, isRestartSubDialogOpen } =
		useContext(PaywallContext);
	const [pricingSelection, setPricingSelection] = useState<string>(null);
	const { user } = useAuth0();
	const plan = useSubscriptionPlanDetails();
	const { enqueueSnackbar } = useSnackbar();
	const stripe = useStripe();

	const handleUpdateExistingCustomer = async (stripePriceId: string) => {
		const response = await updateExistingCustomer(
			stripePriceId,
			user,
			plan.customerId
		);
		// if there is no sessionId returned, then we have an error
		if (response.status === 'error' || !response.data.sessionId) {
			enqueueSnackbar(
				'There was a problem. Please contact support if the issue persists.',
				{
					anchorOrigin: {
						horizontal: 'right',
						vertical: 'top'
					},
					variant: 'error'
				}
			);
			return;
		}
		stripe.redirectToCheckout({ sessionId: response.data.sessionId });
	};

	return (
		<div>
			<Dialog
				aria-labelledby="subscription-modal"
				open={isRestartSubDialogOpen}
				fullWidth={true}
				maxWidth={'sm'}
				onClose={() => setIsRestartSubDialogOpen(false)}
			>
				<DialogContent dividers>
					<div style={{ textAlign: 'center' }}>
						<img src="/logoBlack.png" alt="logo" />
					</div>
					<Typography marginTop="1rem" textAlign="center" variant="h5">
						Choose a plan to continue
					</Typography>
					<PricingOptions
						pricingSelection={pricingSelection}
						setPricingSelection={setPricingSelection}
					/>
					<Button
						fullWidth
						color="primary"
						variant={'contained'}
						disabled={!pricingSelection}
						onClick={() => handleUpdateExistingCustomer(pricingSelection)}
					>
						Subscribe Now
					</Button>
				</DialogContent>
			</Dialog>
		</div>
	);
};
