import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Box, Divider, Typography, Button, Tooltip } from '@mui/material';
import { Alert, AlertTitle, LoadingButton } from '@material-ui/lab';
import { saveAs } from 'file-saver';
import FavoriteIcon from '@material-ui/icons/Favorite';
import DownloadIcon from '@material-ui/icons/Download';
import ShareIcon from '@material-ui/icons/Share';
import ContentCopy from '@material-ui/icons/ContentCopy';
import XIcon from 'src/packages/icons/X';
import CanvaIcon from 'src/packages/icons/CanvaIcon';
import RefreshIcon from 'src/packages/icons/Refresh';
import { useUserStore } from 'src/packages/state';
import useSettings from 'src/packages/hooks/useSettings';
import { CustomCaptionsContext } from 'src/packages/custom-captions';
import useClipboard from 'react-use-clipboard';
import { useSavedSocialStore } from 'src/packages/lib/saved-social';
import { ImageUploadButton } from 'src/packages/media-upload/components/ImageUploadButton';
import { generateCaptionVariations } from 'src/packages/services';

const PreviewMedia = ({ post, handleClose, setPublish, styles }) => {
	const navigate = useNavigate();
	const { settings } = useSettings();
	const { enqueueSnackbar } = useSnackbar();
	const { useCustomCaptions, setRegeneratedCaption, regeneratedCaption } =
		useContext(CustomCaptionsContext);

	const { initialSavedPosts, setSavedPostsPayload, isUpdatingSavedPosts } =
		useSavedSocialStore();
	const [buttonStyle, setButtonStyle] = useState<
		'text' | 'outlined' | 'contained'
	>('outlined');
	const { userDetails } = useUserStore();
	const [commentTooltip, setCommentTooltip] = useState(false);
	const [hashtagTooltip, setHashtagTooltip] = useState(false);
	const [bothTooltip, setBothTooltip] = useState(false);

	const { preparedCaption, preparedHashtags } = useCustomCaptions(
		userDetails?.user_metadata?.captionSettings,
		post?.node?.featuredPost?.recommendedcaption,
		post?.node?.featuredPost?.popularhashtags
	);

	const [caption, setCaption] = useState(regeneratedCaption || preparedCaption);
	const [isGeneratingCaption, setIsGeneratingCaption] = useState(false);

	const [isCaptionCopied, setCaptionCopied] = useClipboard(
		regeneratedCaption || preparedCaption
	);
	const [isHashtagsCopied, setHashtagsCopied] = useClipboard(preparedHashtags);
	const [isBothCopied, setIsBothCopied] = useClipboard(
		`${regeneratedCaption || preparedCaption}` +
			`\n` +
			`\n` +
			`${preparedHashtags}`
	);

	// state value to toggle icon
	const [isPostSaved, setIsPostSaved] = useState(
		initialSavedPosts.includes(post.node.databaseId)
	);

	const throwTooltip = (target: string) => {
		target === 'caption'
			? setCommentTooltip(true)
			: target === 'tags'
			? setHashtagTooltip(true)
			: setBothTooltip(true);

		setTimeout(() => {
			setCommentTooltip(false);
			setHashtagTooltip(false);
			setBothTooltip(false);
		}, 1000);
	};

	const regenerateCaption = async (caption: string) => {
		setIsGeneratingCaption(true);
		const result = await generateCaptionVariations(caption);

		if (result.status === 'error') {
			enqueueSnackbar(result.statusText, {
				anchorOrigin: {
					horizontal: 'right',
					vertical: 'top'
				},
				variant: 'error'
			});
			setIsGeneratingCaption(false);
			return;
		}

		const newCaption = result?.data?.caption[0].replace(/^"(.*)"$/, '$1');
		setCaption(newCaption);
		setRegeneratedCaption(newCaption);
		setIsGeneratingCaption(false);
	};

	const downloadImage = async () => {
		saveAs(
			post?.node?.featuredPost?.postimage?.mediaItemUrl,
			post?.node?.featuredPost?.postimage?.title
		);
	};

	return (
		<Box display={'flex'} flexDirection={'column'} flex={1}>
			{!userDetails?.user_metadata?.captionSettings && (
				<Box sx={{ mb: '1rem', cursor: 'pointer' }}>
					<Alert
						severity="error"
						onClick={() => {
							navigate('/dashboard/branding/captionSettings');
						}}
					>
						<AlertTitle>
							<b>
								Before you can publish any posts, you need to enter your Custom Caption
								details. <br />
								Click here to enter your Custom Caption details.
							</b>
						</AlertTitle>
					</Alert>
				</Box>
			)}
			<Box sx={{ mb: '2rem' }} display="flex" flexDirection="column">
				<Typography
					color={'textPrimary'}
					sx={{ mt: 1, fontSize: '0.9rem' }}
					variant={'overline'}
				>
					Suggested Caption
				</Typography>
				<Typography id={'caption'} gutterBottom>
					{caption}
				</Typography>
				<LoadingButton
					sx={{ alignSelf: 'flex-end' }}
					loading={isGeneratingCaption}
					loadingPosition="start"
					startIcon={<RefreshIcon />}
					variant="contained"
					onClick={() => regenerateCaption(preparedCaption)}
				>
					Regenerate caption
				</LoadingButton>
			</Box>
			<Divider />
			{post?.node?.featuredPost?.popularhashtags && (
				<Box sx={{ mb: '2rem', mt: '2rem' }}>
					<Typography
						id={'hashtags'}
						color={'textPrimary'}
						sx={{ mt: 1, fontSize: '0.9rem' }}
						variant={'overline'}
					>
						Popular Hashtags
					</Typography>
					<Typography gutterBottom>{preparedHashtags}</Typography>
				</Box>
			)}
			<Divider />
			<Box
				display="flex"
				flexDirection="column"
				justifyContent="flex-end"
				height="100%"
			>
				<Box
					display={'flex'}
					justifyContent={'flex-end'}
					sx={{ mb: '1rem', mt: '1rem' }}
				>
					<Tooltip
						arrow
						placement={'top'}
						title={'Copied!'}
						disableHoverListener
						open={commentTooltip}
					>
						<Button
							variant="contained"
							endIcon={<ContentCopy />}
							color={settings.theme === 'DARK' ? 'primary' : 'inherit'}
							sx={{ borderRadius: '5px', marginRight: '1rem' }}
							onClick={() => {
								setCaptionCopied();
								throwTooltip('caption');
							}}
						>
							Caption
						</Button>
					</Tooltip>
					<Tooltip
						arrow
						placement={'top'}
						title={'Copied!'}
						disableHoverListener
						open={hashtagTooltip}
					>
						<Button
							variant="contained"
							endIcon={<ContentCopy />}
							color={settings.theme === 'DARK' ? 'primary' : 'inherit'}
							sx={{ borderRadius: '5px', marginRight: '1rem' }}
							onClick={() => {
								setHashtagsCopied();
								throwTooltip('tags');
							}}
						>
							Hashtags
						</Button>
					</Tooltip>
					<Tooltip
						arrow
						placement={'top'}
						title={'Copied!'}
						disableHoverListener
						open={bothTooltip}
						onClick={() => {
							setIsBothCopied();
							throwTooltip('both');
						}}
					>
						<Button
							variant="contained"
							endIcon={<ContentCopy />}
							color={settings.theme === 'DARK' ? 'primary' : 'inherit'}
							sx={{ borderRadius: '5px' }}
						>
							Both
						</Button>
					</Tooltip>
				</Box>
				<Box
					display="flex"
					justifyContent="flex-end"
					sx={{ mb: '1rem', columnGap: '1rem' }}
				>
					<ImageUploadButton styles={{ borderRadius: '10px' }} />
					<Button
						size="large"
						endIcon={<CanvaIcon />}
						style={{ borderRadius: 10 }}
						variant={buttonStyle}
						onClick={() => {
							if (post?.node?.featuredPost?.canvalink) {
								window.open(post?.node?.featuredPost?.canvalink, '_blank');
							}
						}}
					>
						Edit In Canva
					</Button>
				</Box>
				<Box display="flex" className={styles.buttonsContainer}>
					<Button
						className={styles.modalButtons}
						endIcon={<ShareIcon />}
						variant="outlined"
						color="primary"
						onClick={() => setPublish(true)}
						sx={{ borderRadius: '5px', marginRight: '1rem', flexGrow: 1 }}
					>
						Publish Post
					</Button>
					{isPostSaved ? (
						<Button
							disabled={isUpdatingSavedPosts}
							className={styles.modalButtons}
							endIcon={<FavoriteIcon />}
							variant={isPostSaved ? 'contained' : 'outlined'}
							color="primary"
							onClick={() => {
								setSavedPostsPayload(post.node.databaseId, true);
								setIsPostSaved(false);
							}}
							sx={{ borderRadius: '5px', marginRight: '1rem' }}
						>
							Unsave Post
						</Button>
					) : (
						<Button
							disabled={isUpdatingSavedPosts}
							className={styles.modalButtons}
							endIcon={<FavoriteIcon />}
							variant={isPostSaved ? 'contained' : 'outlined'}
							color="primary"
							onClick={() => {
								setSavedPostsPayload(post.node.databaseId);
								setIsPostSaved(true);
							}}
							sx={{ borderRadius: '5px', marginRight: '1rem' }}
						>
							Save Post
						</Button>
					)}
					<Button
						className={styles.modalButtons}
						endIcon={<DownloadIcon />}
						variant="outlined"
						color="primary"
						onClick={() => {
							downloadImage();
						}}
						sx={{ borderRadius: '5px', marginRight: '1rem' }}
					>
						Download Post
					</Button>
					<Button
						className={styles.modalButtons}
						endIcon={<XIcon />}
						variant="outlined"
						color="primary"
						onClick={() => handleClose()}
						sx={{ borderRadius: '5px' }}
					>
						Close
					</Button>
				</Box>
			</Box>
		</Box>
	);
};

export default PreviewMedia;
