import axios from 'axios';
import { API } from '../../../lib/api/api';

// Deletes a users instagram page from our database
// and all instagram scheduled posts for that page
export const deleteInstagramPage = async (
	id: number,
	facebookPageId: string,
	facebookUserId: string,
	instagramAccountId: string
) => {
	try {
		return await axios.get(
			`${API}instagram/deletePage?id=${id}&facebookPageId=${facebookPageId}&facebookUserId=${facebookUserId}&instagramAccountId=${instagramAccountId}`
		);
	} catch (e) {
		return {
			error: e,
			status: 'error',
			statusText: 'There was a problem deleting your connected Instagram page.',
			data: e
		};
	}
};
