import { Dispatch, createContext } from 'react';
import { IdToken } from '@auth0/auth0-react';
export interface UnlinkAccountPayload {
	provider: string;
	user_id: string;
	connection: string;
}

export interface SocialProfilesContext {
	unlinkSocialProfile?: (payload: UnlinkAccountPayload) => Promise<void>;
	isUnLinkingAccount?: boolean;
	isLinkedInConfirmModalOpen?: boolean;
	setIsLinkedInConfirmModalOpen?: (arg: boolean) => void;
	setIsUnLinkingAccount?: (arg: boolean) => void;
	isConfirmModalOpen?: boolean;
	setIsConfirmModalOpen?: (arg: boolean) => void;
	authenticateUser?: (connection: string) => Promise<IdToken>;
	linkSocialProfile?: (connection: string) => void;
	deleteFacebookPage?: (
		id: number,
		facebookPageId: string,
		facebookUserId: string
	) => void;
	deleteInstagramPage?: (
		id: number,
		facebookPageId: string,
		facebookUserId: string
	) => void;
	selectedProfile?: any;
	setSelectedProfile?: Dispatch<any>;
}

export const SocialProfilesContext = createContext<SocialProfilesContext>(null);
