import React from 'react';
import {
	Avatar,
	Box,
	Card,
	Grid,
	LinearProgress,
	Typography,
	CardActionArea,
	CardMedia
} from '@mui/material';
import SimpleImageSlider from 'react-simple-image-slider';
import { makeStyles, useTheme } from '@mui/styles';
import { useQuery } from '@apollo/client';
import Label from '../Label';
import {
	GET_STYLE_IT_CATEGORIES_BY_SLUG,
	GET_DESIGN_CHOICE_SLIDESHOW_PHOTOS
} from 'src/packages/lib/gql-queries';
import { useDesignChoiceSliderPhotos } from 'src/packages/hooks';
import { useUserStore } from 'src/packages/state';

const useStyles = makeStyles({
	root: {
		maxWidth: 345
	},
	media: {
		height: 200
	},
	modalTitle: {
		paddingBottom: '10px'
	},
	tile: {
		position: 'relative'
	},
	tileTitle: {
		position: 'absolute',
		color: 'black',
		backgroundColor: 'rgba(255, 255, 255, 0.9)',
		padding: '4%',
		bottom: '0',
		textAlign: 'center',
		width: '100%',
		pointerEvents: 'none'
	}
});

interface StyleItOptionsProps {
	setStyleSelection: any;
	styleSelection: string;
}

const organizeCategories = (data) => {
	const allPosts = data?.tags?.nodes[0]?.contentNodes.nodes;
	const maverick = allPosts
		?.filter((post) => {
			return (
				post.categories.nodes.filter((cat) => cat.slug === 'maverick').length > 0
			);
		})
		.map((post) => {
			return {
				url: post.featuredPost.postimage.mediaItemUrl
			};
		});

	const moody = allPosts
		?.filter((post) => {
			return (
				post.categories.nodes.filter((cat) => cat.slug === 'moody').length > 0
			);
		})
		.map((post) => {
			return {
				url: post.featuredPost.postimage.mediaItemUrl
			};
		});

	const modern = allPosts
		?.filter((post) => {
			return (
				post.categories.nodes.filter((cat) => cat.slug === 'modern').length > 0
			);
		})
		.map((post) => {
			return {
				url: post.featuredPost.postimage.mediaItemUrl
			};
		});

	return [
		{ photoSet: maverick, name: 'The Maverick' },
		{ photoSet: modern, name: 'The Modern' },
		{ photoSet: moody, name: 'The Moody' }
	];
};

const StyleItOptions = ({
	setStyleSelection,
	styleSelection
}: StyleItOptionsProps) => {
	const { stylePreference } = useUserStore();
	const classes = useStyles();
	const theme = useTheme();

	const { loading, error, data } = useQuery(GET_DESIGN_CHOICE_SLIDESHOW_PHOTOS);

	const categories = organizeCategories(data);

	if (loading) {
		return (
			<Box sx={{ mt: 2, mb: 2 }}>
				<LinearProgress />
			</Box>
		);
	}

	if (error) return <div>`Error! ${error.message}`</div>;

	return (
		<Box
			sx={{
				backgroundColor: 'background.default',
				p: 3
			}}
		>
			<Grid container spacing={3}>
				{categories.map((category) => {
					return (
						<Grid item xs={12} md={6} lg={4}>
							<Card
								sx={{
									boxSizing: 'border-box',
									alignItems: 'center',
									display: 'flex',
									justifyContent: 'space-between',
									position: 'relative',
									outline:
										styleSelection === category.name || stylePreference === category.name
											? `3px solid ${theme.palette.primary.main}`
											: '0'
								}}
								onClick={() => {
									setStyleSelection(category.name);
								}}
							>
								<CardActionArea>
									{category.photoSet.length > 0 && (
										<SimpleImageSlider
											width={'100%'}
											height={430}
											autoPlay={true}
											images={category.photoSet}
											showBullets={false}
											showNavs={true}
										/>
									)}
								</CardActionArea>
								<Typography variant="h5" className={classes.tileTitle}>
									{category.name}
								</Typography>
							</Card>
						</Grid>
					);
				})}
			</Grid>
		</Box>
	);
};

export default StyleItOptions;
