import { redirectUri } from './packages/lib/environment/environment';

export const amplifyConfig = {
	aws_project_region: import.meta.env.VITE_AWS_PROJECT_REGION,
	aws_cognito_identity_pool_id: import.meta.env
		.VITE_AWS_COGNITO_IDENTITY_POOL_ID,
	aws_cognito_region: import.meta.env.VITE_AWS_COGNITO_REGION,
	aws_user_pools_id: import.meta.env.VITE_AWS_USER_POOLS_ID,
	aws_user_pools_web_client_id: import.meta.env.VITE_AWS_USER_POOLS_WEB_CLIENT_ID
};

export const wpGraphQLConfig = {
	uri: import.meta.env.VITE_WP_GRAPH_QL_ENDPOINT
};

export const auth0Config = {
	client_id: import.meta.env.VITE_AUTH0_CLIENT_ID,
	audience: import.meta.env.VITE_AUTH0_AUDIENCE_URL,
	domain: import.meta.env.VITE_AUTH0_URL,
	redirectUri,
	logoutRedirectUri: import.meta.env.VITE_AUTH0_LOGOUT_REDIRECT_URI
};

export const awsConfig = {
	key: import.meta.env.VITE_AWS_ACCESS_KEY,
	s3BucketName: import.meta.env.VITE_AWS_S3_BUCKET_NAME,
	region: import.meta.env.VITE_AWS_S3_REGION,
	identityPoolId: import.meta.env.VITE_AWS_IDENTITY_POOL_ID
};

export const stripeKeys = {
	publishableKey: import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY,
	secretKey: import.meta.env.VITE_STRIPE_SECRET_KEY,
	yearlyPriceId: import.meta.env.VITE_STRIPE_YEARLY_PRICE_ID,
	monthlyPriceId: import.meta.env.VITE_STRIPE_MONTHLY_PRICE_ID
};

export const firebaseConfig = {
	apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
	appId: import.meta.env.VITE_FIREBASE_APP_ID,
	authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
	databaseURL: import.meta.env.VITE_FIREBASE_DATABASE_URL,
	messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
	projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
	storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET
};

export const gtmConfig = {
	containerId: import.meta.env.VITE_GTM_CONTAINER_ID
};
