import { Suspense, lazy } from 'react';
import type { RouteObject } from 'react-router';
import AuthGuard from './packages/components/AuthGuard';
import BlogLayout from './packages/components/blog/BlogLayout';
import BrowseLayout from './packages/components/BrowseLayout';
import DashboardLayout from './packages/components/dashboard/DashboardLayout';
import DocsLayout from './packages/components/docs/DocsLayout';
import GuestGuard from './packages/components/GuestGuard';
import LoadingScreen from './packages/components/LoadingScreen';
import MainLayout from './packages/components/MainLayout';
import Home from './packages/pages/Home';
import Browse from './packages/pages/browse/Browse';

// Contexts
import { MediaBrowserProvider } from './packages/media-browser/context';
import { ContentByCategoryProvider } from './packages/content-and-captions/content-by-category';
import { ThisMonthsMediaProvider } from './packages/content-and-captions/this-months-media';
import { NextMonthsMediaProvider } from './packages/content-and-captions/next-months-media';
import { SocialProfilesProvider } from './packages/social-profiles/context';

const Loadable = (Component) => (props) =>
	(
		<Suspense fallback={<LoadingScreen />}>
			<Component {...props} />
		</Suspense>
	);

// Dashboard pages

const Account = Loadable(
	lazy(() => import('./packages/admin-panel/account/Account'))
);
const BrandingCenter = Loadable(
	lazy(() => import('./packages/admin-panel/branding-center/BrandingCenter'))
);
const Calendar = Loadable(
	lazy(() => import('./packages/calendar/components/Calendar'))
);
const ContentByCategory = Loadable(
	lazy(
		() =>
			import(
				'./packages/content-and-captions/content-by-category/components/ContentByCategory'
			)
	)
);
const ThisMonthsMedia = Loadable(
	lazy(
		() =>
			import(
				'./packages/content-and-captions/this-months-media/components/ThisMonthsMedia'
			)
	)
);
const NextMonthsMedia = Loadable(
	lazy(
		() =>
			import(
				'./packages/content-and-captions/next-months-media/components/NextMonthsMedia'
			)
	)
);
const SavedSocial = Loadable(
	lazy(
		() =>
			import('./packages/content-and-captions/saved-social/components/SavedSocial')
	)
);
const SocialBySize = Loadable(
	lazy(
		() =>
			import(
				'./packages/content-and-captions/social-by-size/components/SocialBySize'
			)
	)
);
const TemplatesAndDownloads = Loadable(
	lazy(
		() =>
			import(
				'./packages/marketing-resources/templates-and-downloads/components/TemplatesAndDownloads'
			)
	)
);
const HelpSupport = Loadable(
	lazy(() => import('./packages/admin-panel/help-support/HelpAndSupport'))
);

// Error pages

const AuthorizationRequired = Loadable(
	lazy(() => import('./packages/pages/AuthorizationRequired'))
);
const NotFound = Loadable(lazy(() => import('./packages/pages/NotFound')));
const ServerError = Loadable(
	lazy(() => import('./packages/pages/ServerError'))
);

const SocialProfiles = Loadable(
	lazy(() => import('./packages/social-profiles/SocialProfiles'))
);

const LandingPage = Loadable(
	lazy(() => import('./packages/landing-page/LandingPage'))
);

const routes: RouteObject[] = [
	{
		path: '/',
		element: (
			<AuthGuard>
				<DashboardLayout />
			</AuthGuard>
		),
		children: [
			{
				path: '/browse',
				element: <Browse />
			},
			{
				path: 'dashboard',
				children: [
					{
						path: '/dashboard',
						element: <LandingPage />
					},
					{
						path: 'help-support',
						element: <HelpSupport />
					},
					{
						path: 'account',
						element: <Account />,
						children: [
							{
								path: 'profile',
								element: <Account />
							},
							{
								path: 'emailPass',
								element: <Account />
							},
							{
								path: 'loginInfo',
								element: <Account />
							},
							{
								path: 'billing',
								element: <Account />
							}
						]
					},
					{
						path: 'branding',
						element: (
							<MediaBrowserProvider>
								<BrandingCenter />
							</MediaBrowserProvider>
						),
						children: [
							{
								path: 'logoPortrait',
								element: <BrandingCenter />
							},
							{
								path: 'fontsColors',
								element: <BrandingCenter />
							},
							{
								path: 'captionSettings',
								element: <BrandingCenter />
							},
							{
								path: 'designChoice',
								element: (
									<MediaBrowserProvider>
										<BrandingCenter />
									</MediaBrowserProvider>
								)
							}
						]
					},
					{
						path: 'calendar',
						element: <Calendar />
					},
					{
						path: 'templates-downloads',
						element: (
							<MediaBrowserProvider>
								<TemplatesAndDownloads />
							</MediaBrowserProvider>
						),
						children: [
							{
								path: 'dashboard/templates-downloads',
								element: (
									<MediaBrowserProvider>
										<TemplatesAndDownloads />
									</MediaBrowserProvider>
								)
							}
						]
					},
					{
						path: 'this-months-media',
						element: (
							<MediaBrowserProvider>
								<ThisMonthsMediaProvider>
									<ThisMonthsMedia />
								</ThisMonthsMediaProvider>
							</MediaBrowserProvider>
						)
					},
					{
						path: 'next-months-media',
						element: (
							<MediaBrowserProvider>
								<NextMonthsMediaProvider>
									<NextMonthsMedia />
								</NextMonthsMediaProvider>
							</MediaBrowserProvider>
						)
					},
					{
						path: 'content-by-category',
						element: (
							<MediaBrowserProvider>
								<ContentByCategoryProvider>
									<ContentByCategory />
								</ContentByCategoryProvider>
							</MediaBrowserProvider>
						)
					},
					{
						path: 'social-by-size',
						element: (
							<MediaBrowserProvider>
								<SocialBySize />
							</MediaBrowserProvider>
						)
					},
					{
						path: 'saved-social',
						element: (
							<MediaBrowserProvider>
								<SavedSocial />
							</MediaBrowserProvider>
						)
					},
					{
						path: 'social-profiles',
						element: (
							<SocialProfilesProvider>
								<SocialProfiles />
							</SocialProfilesProvider>
						)
					}
				]
			}
		]
	}
	// {
	// 	path: '*',
	// 	element: <MainLayout />,
	// 	children: [
	// 		{
	// 			path: '/',
	// 			element: <Navigate to={'/dashboard'} />
	// 		},
	// 		{
	// 			path: '401',
	// 			element: <AuthorizationRequired />
	// 		},
	// 		{
	// 			path: '404',
	// 			element: <NotFound />
	// 		},
	// 		{
	// 			path: '500',
	// 			element: <ServerError />
	// 		},
	// 		{
	// 			path: '*',
	// 			element: <NotFound />
	// 		}
	// 	]
	// }
];

export default routes;
