import * as React from 'react';
import { useContext } from 'react';
import { useQuery } from '@apollo/client';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { PaywallContext } from 'src/packages/paywall';

export const MonthlyDownloadButton = ({ options, buttonTitle }) => {
	const [open, setOpen] = React.useState(false);
	const anchorRef = React.useRef<HTMLDivElement>(null);
	const [selectedIndex, setSelectedIndex] = React.useState(1);
	const {
		userSubscriptionDetailsCopy,
		setIsPaymentMethodDialogOpen,
		shouldShowPaymentMethodDialog,
		setIsFreeTrialTeaserDialogOpen,
		setIsRestartSubDialogOpen,
		setIsPaymentPastDueDialogOpen,
		userSubscriptionStatus
	} = useContext(PaywallContext);

	const handleClick = () => {
		console.info(`You clicked ${options[selectedIndex]}`);
	};

	const handleMenuItemClick = (
		event: React.MouseEvent<HTMLLIElement, MouseEvent>,
		link: string
	) => {
		if (!userSubscriptionDetailsCopy) {
			setIsFreeTrialTeaserDialogOpen(true);
			return;
		}
		if (userSubscriptionDetailsCopy.status === 'canceled') {
			setIsRestartSubDialogOpen(true);
			return;
		}
		if (userSubscriptionStatus.pastDue) {
			setIsPaymentPastDueDialogOpen(true);
			return;
		}
		if (shouldShowPaymentMethodDialog) {
			setIsPaymentMethodDialogOpen(true);
			return;
		}
		window.open(`${link}`, '_blank');
		setOpen(false);
	};

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event: Event) => {
		if (
			anchorRef.current &&
			anchorRef.current.contains(event.target as HTMLElement)
		) {
			return;
		}

		setOpen(false);
	};

	return (
		<React.Fragment>
			<ButtonGroup
				variant="contained"
				ref={anchorRef}
				aria-label="Button group with a nested menu"
			>
				<Button sx={{ background: '#1f6a5c' }} onClick={handleToggle}>
					{buttonTitle}
				</Button>
				<Button
					sx={{ background: '#1f6a5c' }}
					size="small"
					aria-controls={open ? 'split-button-menu' : undefined}
					aria-expanded={open ? 'true' : undefined}
					aria-label="select content category"
					aria-haspopup="menu"
					onClick={handleToggle}
				>
					<ArrowDropDownIcon />
				</Button>
			</ButtonGroup>
			<Popper
				sx={{
					zIndex: 1
				}}
				open={open}
				anchorEl={anchorRef.current}
				role={undefined}
				transition
				disablePortal
				placement="bottom"
			>
				{({ TransitionProps, placement = 'bottom' }) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin:
								placement === 'bottom' ? 'center bottom' : 'center bottom'
						}}
					>
						<Paper>
							<ClickAwayListener onClickAway={handleClose}>
								<MenuList id="split-button-menu" autoFocusItem>
									{options.map((option, index) => (
										<MenuItem
											key={`${option}-${index}`}
											onClick={(event) => handleMenuItemClick(event, option.link)}
										>
											{option.title}
										</MenuItem>
									))}
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</React.Fragment>
	);
};
