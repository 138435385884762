import React, { useContext } from 'react';
import { useSnackbar } from 'notistack';
import { postToLinkedIn } from '../../services';
import { LinkedinContext } from './LinkedinContext';
import { CalendarContext } from 'src/packages/calendar/context';
import { useUserStore } from 'src/packages/state';
import { useAuth0 } from '@auth0/auth0-react';

export const LinkedinProvider: React.FC = ({ children }) => {
	const { enqueueSnackbar } = useSnackbar();
	const { fetchAllScheduledPosts } = useContext(CalendarContext);
	const { userDetails, linkedInPages } = useUserStore();
	const { user } = useAuth0();

	const handleResponse = (res) => {
		if (res.status === 200) {
			enqueueSnackbar('Successful LinkedIn post!', {
				anchorOrigin: {
					horizontal: 'right',
					vertical: 'top'
				},
				variant: 'success'
			});
		}
		if (res.status === 'error') {
			enqueueSnackbar(res.statusText, {
				anchorOrigin: {
					horizontal: 'right',
					vertical: 'top'
				},
				variant: 'error'
			});
		}
	};

	const handleLinkedinPost = async (
		profile,
		caption: string,
		preparedCaption: string,
		preparedHashtags: string,
		captionChoice: string,
		isScheduled: boolean,
		utcTime: string,
		imgUrl: string
	) => {
		let captionSelection;
		const encodedHashtags = escape(preparedHashtags);

		switch (captionChoice) {
			case 'caption':
				captionSelection = preparedCaption;
				break;
			case 'hashtags':
				captionSelection = encodedHashtags;
				break;
			case 'both':
				captionSelection =
					`${preparedCaption}` + '%0A' + '%0A' + `${encodedHashtags}`;
				break;
			case 'custom':
				captionSelection = escape(caption);
				break;
			default:
				captionSelection = '';
				break;
		}

		const { user_id, access_token } = profile;

		const res = await postToLinkedIn(
			user_id,
			access_token,
			captionSelection,
			imgUrl,
			'newTitle',
			'newCaption',
			isScheduled,
			utcTime,
			user.sub,
			profile.name
		);

		if (isScheduled) {
			// refetch scheduled posts for the Calendar view
			fetchAllScheduledPosts(userDetails);
		}

		return handleResponse(res);
	};

	return (
		<LinkedinContext.Provider value={{ handleLinkedinPost, linkedInPages }}>
			{children}
		</LinkedinContext.Provider>
	);
};
