import { gql } from '@apollo/client';

const limitedContentQuery = gql`
	query GetSavedPosts($ids: [ID]) {
		posts(first: 300, where: { in: $ids }) {
			edges {
				node {
					id
					featuredPost {
						suggestedpostdate
						postimage {
							mediaItemUrl
							mimeType
						}
						showdownloadbutton
					}
					databaseId
					categories {
						edges {
							node {
								id
								parent {
									node {
										name
										slug
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;

const protectedContentQuery = gql`
	query GetSavedPosts($ids: [ID]) {
		posts(first: 300, where: { in: $ids }) {
			edges {
				node {
					id
					featuredPost {
						recommendedcaption
						suggestedpostdate
						postimage {
							mediaItemUrl
							mimeType
						}
						popularhashtags
						canvalink
						showdownloadbutton
					}
					databaseId
					categories {
						edges {
							node {
								id
								parent {
									node {
										name
										slug
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;

export const getPostsById = (hasActiveSubscription) => {
    return hasActiveSubscription ? protectedContentQuery : limitedContentQuery;
}