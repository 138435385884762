import React, { useState, useEffect, useContext } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import {
	Box,
	Card,
	Divider,
	Grid,
	IconButton,
	Tooltip,
	Typography,
	Button
} from '@mui/material';
import red from '@material-ui/core/colors/red';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import {
	MediaContainer,
	MediaContainerContext
} from 'src/packages/media-container';
import { useSavedSocialStore } from 'src/packages/lib/saved-social';
import { PaywallContext } from 'src/packages/paywall';

interface MediaCardProps {
	post?: any;
	isFreePost?: boolean;
}

export const MediaCard: FC<MediaCardProps> = (props) => {
	const { post, isFreePost, ...other } = props;
	const { initialSavedPosts, setSavedPostsPayload, isUpdatingSavedPosts } =
		useSavedSocialStore();
	const {
		userSubscriptionDetailsCopy,
		setIsPaymentMethodDialogOpen,
		shouldShowPaymentMethodDialog,
		setIsFreeTrialTeaserDialogOpen,
		setIsRestartSubDialogOpen,
		setIsPaymentPastDueDialogOpen,
		userSubscriptionStatus
	} = useContext(PaywallContext);
	// state to toggle the media container
	const [open, setOpen] = useState(false);
	// state value to toggle icon
	const [isPostSaved, setIsPostSaved] = useState(
		initialSavedPosts.includes(post.node.databaseId)
	);

	// @todo: change this to use CSS not JS...
	const [buttonStyle, setButtonStyle] = useState<
		'text' | 'outlined' | 'contained'
	>('outlined');

	const handleClickOpen = () => {
		if (isFreePost) {
			setOpen(true);
			return;
		}
		if (!userSubscriptionDetailsCopy) {
			setIsFreeTrialTeaserDialogOpen(true);
			return;
		}
		if (userSubscriptionDetailsCopy.status === 'canceled') {
			setIsRestartSubDialogOpen(true);
			return;
		}
		if (userSubscriptionStatus.pastDue) {
			setIsPaymentPastDueDialogOpen(true);
			return;
		}
		if (shouldShowPaymentMethodDialog) {
			setIsPaymentMethodDialogOpen(true);
			return;
		}
		setOpen(true);
	};

	useEffect(() => {
		setIsPostSaved(initialSavedPosts.includes(post.node.databaseId));
	}, [initialSavedPosts]);

	return (
		<>
			<Card {...other}>
				<Box
					onMouseEnter={() => setButtonStyle('contained')}
					onMouseLeave={() => setButtonStyle('outlined')}
					onClick={() => handleClickOpen()}
					sx={{ cursor: 'pointer' }}
				>
					<Box
						sx={{ pl: 2, pr: 2, pt: 2, cursor: 'pointer' }}
						onMouseEnter={() => setButtonStyle('contained')}
						onMouseLeave={() => setButtonStyle('outlined')}
					>
						<img
							style={{ pointerEvents: 'none' }}
							width={'100%'}
							height={'100%'}
							src={post?.node?.featuredPost?.postimage?.mediaItemUrl}
							alt=""
						/>
					</Box>
					{!post?.node?.featuredPost?.suggestedpostdate && <Box sx={{ py: 2.25 }} />}
					{post?.node?.featuredPost?.suggestedpostdate && (
						<Box
							sx={{
								px: 3,
								py: 1
							}}
						>
							<Grid
								alignItems="center"
								container
								justifyContent="space-between"
								spacing={3}
							>
								<Grid item>
									<Typography color="textSecondary" variant="body2">
										Suggested Post Date:
									</Typography>
								</Grid>
								<Grid item>
									<Typography color="textSecondary" variant="body2">
										{post?.node?.featuredPost?.suggestedpostdate}
									</Typography>
								</Grid>
							</Grid>
						</Box>
					)}
				</Box>
				<Divider />
				<Box
					sx={{
						alignItems: 'center',
						display: 'flex',
						pl: 2,
						pr: 3,
						py: 2
					}}
				>
					<Box
						sx={{
							alignItems: 'center',
							display: 'flex'
						}}
					>
						{isPostSaved ? (
							<Tooltip title="Unsave Post">
								<IconButton
									disabled={isUpdatingSavedPosts}
									onClick={() => {
										setSavedPostsPayload(post.node.databaseId, true);
										setIsPostSaved(false);
									}}
									sx={{ color: red['600'] }}
								>
									<FavoriteIcon fontSize="large" />
								</IconButton>
							</Tooltip>
						) : (
							<Tooltip title="Save Post">
								<IconButton
									disabled={isUpdatingSavedPosts}
									onClick={() => {
										if (isFreePost) {
											setSavedPostsPayload(post.node.databaseId);
											setIsPostSaved(true);
											return;
										}
										if (!userSubscriptionDetailsCopy) {
											setIsFreeTrialTeaserDialogOpen(true);
											return;
										}
										if (userSubscriptionDetailsCopy.status === 'canceled') {
											setIsRestartSubDialogOpen(true);
											return;
										}
										if (userSubscriptionStatus.pastDue) {
											setIsPaymentPastDueDialogOpen(true);
											return;
										}
										if (shouldShowPaymentMethodDialog) {
											setIsPaymentMethodDialogOpen(true);
											return;
										}
										setSavedPostsPayload(post.node.databaseId);
										setIsPostSaved(true);
									}}
								>
									<FavoriteBorderIcon fontSize="large" />
								</IconButton>
							</Tooltip>
						)}
					</Box>
					<Box sx={{ flexGrow: 1 }}>
						<Button
							style={{ borderRadius: 10, width: '100%' }}
							onMouseEnter={() => setButtonStyle('contained')}
							onMouseLeave={() => setButtonStyle('outlined')}
							variant={buttonStyle}
							onClick={() => handleClickOpen()}
						>
							Preview Post
						</Button>
					</Box>
				</Box>
			</Card>
			<MediaContainer open={open} setOpen={setOpen} post={post} />
		</>
	);
};

MediaCard.propTypes = {
	post: PropTypes.object.isRequired
};
