import { API } from '../../../lib/api/api';
import axios from 'axios';

// function to delete all linkedin scheduled posts from our rds database
export const deleteAllScheduledPosts = async (userId: string) => {
	try {
		return await axios.get(
			`${API}linkedin/deleteUserScheduledPosts?auth0UserId=${userId}`
		);
	} catch (e) {
		return {
			error: e,
			status: 'error',
			statusText: 'There was an error. Your scheduled posts were not deleted.',
			data: e
		};
	}
};
