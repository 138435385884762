import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-quill/dist/quill.snow.css';
import 'nprogress/nprogress.css';
import './packages/__mocks__';
import React from 'react';
import { createRoot } from 'react-dom/client';
import ReactDOM from 'react-dom';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import StyledEngineProvider from '@material-ui/core/StyledEngineProvider';
import App from './packages/app/App';
import Bootstrap from './packages/app/Bootstrap';
import { AuthProvider } from './packages/contexts/Auth0Context';
import { FacebookPermissionsProvider } from './packages/shell/facebook-permissions/src/FacebookPermissionsProvider';
import { CalendarProvider } from './packages/calendar/context/CalendarContextWrapper';
import { PaywallProvider } from './packages/paywall';
import { MediaContainerProvider } from './packages/media-container/context';
import { wpGraphQLConfig } from './config';
import { SettingsProvider } from './packages/contexts/SettingsContext';
import store from './packages/store';

const client = new ApolloClient({
	uri: wpGraphQLConfig.uri,
	cache: new InMemoryCache()
});

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
	<HelmetProvider>
		<ReduxProvider store={store}>
			<StyledEngineProvider injectFirst>
				<LocalizationProvider dateAdapter={AdapterDateFns}>
					<SettingsProvider>
						<BrowserRouter>
							<AuthProvider>
								<Bootstrap>
									<PaywallProvider>
										<FacebookPermissionsProvider>
											<CalendarProvider>
												<ApolloProvider client={client}>
													<MediaContainerProvider>
														<App />
													</MediaContainerProvider>
												</ApolloProvider>
											</CalendarProvider>
										</FacebookPermissionsProvider>
									</PaywallProvider>
								</Bootstrap>
							</AuthProvider>
						</BrowserRouter>
					</SettingsProvider>
				</LocalizationProvider>
			</StyledEngineProvider>
		</ReduxProvider>
	</HelmetProvider>,
	document.getElementById('root')
);
