import { API } from 'src/packages/lib/api/api';
import axios, { AxiosRequestConfig } from 'axios';

export const getUser = async (userId: string) => {
	try {
		return await axios.get(`${API}user/${userId}`);
	} catch (e) {
		return e;
	}
};

export const updateUserStylePreference = async (
	userId: string,
	stylePreference: string
) => {
	return await axios.post(`${API}user/updateStylePreference/${userId}`, {
		stylePreference
	} as AxiosRequestConfig);
};

export const updateUserCaptionSettings = async (
	userId: string,
	captionSettings
) => {
	return await axios.post(`${API}user/updateCaptionSettings/${userId}`, {
		captionSettings
	});
};

export const updateUserMetaData = async (
	userId: string,
	metaDataProperty: string,
	value: any
) => {
	const response = await axios.post(`${API}update-user-metadata`, {
		userId,
		metaDataProperty,
		value
	});
	return response;
};

export const updateUserLikedPosts = async (
	userDetails: any,
	postId: string,
	isLiked: boolean
) => {
	return await axios.post(`${API}user/updateLikedPosts/${userDetails.user_id}`, {
		userDetails,
		postId,
		isLiked
	});
};

export const updateUserSavedSocial = async (
	userDetails,
	savedSocialPayload
) => {
	return await axios.post(`${API}user/updateLikedPosts`, {
		userId: userDetails?.user_id,
		savedSocialPayload
	});
};

export const initiatePasswordReset = async (userEmail: string) => {
	return await axios.post(`${API}user/forgotPassword/${userEmail}`);
};
