import { createContext } from 'react';

export interface LinkedinContext {
	handleLinkedinPost?: (
		profile,
		caption: string,
		preparedCaption: string,
		preparedHashtags: string,
		captionChoice: string,
		isScheduled: boolean,
		utcTime: string,
		imgUrl: string
	) => void;
	linkedInPages: any;
}

export const LinkedinContext = createContext<LinkedinContext>(null);
