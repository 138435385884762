import { Alert, Typography } from '@mui/material';

export const NoPosts = () => (
	<Alert severity="info">
		<Typography color="textPrimary">
			Use the highlighted category selections above to select social posts from our
			curated collections.
		</Typography>
	</Alert>
);
