import { useContext } from 'react';
import { Box, Alert, AlertTitle } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { CustomCaptionsContext } from 'src/packages/custom-captions';
import {
	DeclinedFBPermissionsMessage,
	DeclinedIGPermissionsMessage
} from 'src/packages/shell/facebook-permissions';
import { ProfileSelect } from './ProfileSelect';

interface PublishViewInterface {
	post?: any;
	setPublish?: any;
}

const PreviewMedia = ({ post, setPublish }: PublishViewInterface) => {
	const { noCustomCaptionDetails } = useContext(CustomCaptionsContext);
	const navigate = useNavigate();

	return (
		<Box
			display="flex"
			flex="1"
			flexDirection="column"
			justifyContent="space-evenly"
		>
			<DeclinedIGPermissionsMessage />
			<DeclinedFBPermissionsMessage />
			{noCustomCaptionDetails && (
				<Box sx={{ mb: '1rem', cursor: 'pointer' }}>
					<Alert
						severity="error"
						onClick={() => {
							navigate('/dashboard/branding/captionSettings');
						}}
					>
						<AlertTitle>
							<b>
								Before you can publish any posts, you need to enter your Custom Caption
								details. <br />
								Click here to enter your Custom Caption details.
							</b>
						</AlertTitle>
					</Alert>
				</Box>
			)}
			<ProfileSelect post={post} setPublish={setPublish} />
		</Box>
	);
};

export default PreviewMedia;
