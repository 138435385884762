import axios from 'axios';
import { API } from '../../../../lib/api/api';

export const getUserPermissions = async (facebookAccessToken: string) => {
	try {
		return await axios.get(
			`${API}facebook/getUserPermissions?accessToken=${facebookAccessToken}`
		);
	} catch (e) {
		return {
			error: e,
			status: 'error',
			statusText: 'There was a problem getting your Facebook permissions.',
			data: e
		};
	}
};
