import { useEffect } from 'react';
import type { FC } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useUserStore } from '../state';
import { useSavedSocialStore } from '../lib/saved-social';

const Bootstrap: FC = ({ children }) => {
	const { user } = useAuth0();
	const { getUserDetails, connectedUserProfiles, getFacebookPages } =
		useUserStore();
	const { setInitialSavedPosts } = useSavedSocialStore();

	useEffect(() => {
		const bootstrapUserDetails = async (user) => {
			const userDetails = await getUserDetails(user.sub);
			userDetails && setInitialSavedPosts(userDetails);
		};
		user && bootstrapUserDetails(user);
	}, [user]);

	useEffect(() => {
		if (connectedUserProfiles && connectedUserProfiles.facebook) {
			try {
				getFacebookPages(connectedUserProfiles.facebook.user_id);
			} catch (error) {
				console.error('Error getting facebook pages');
			}
		}
	}, [connectedUserProfiles]);

	return <>{children}</>;
};

export default Bootstrap;
