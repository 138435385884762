import { gql } from '@apollo/client';

export const getCustomerNoteLink = gql`
	query GetCustomerNoteLink($names: [String]) {
		categories(where: { name: $names }) {
			edges {
				node {
					id
					posts {
						nodes {
							customerNoteLink {
								link
								linktext
							}
						}
					}
				}
			}
		}
	}
`;
