export enum Environment {
	Local,
	Preview,
	Sandbox,
	Staging,
	Production
}

const detectEnvironment = () => {
	const { hostname } = window.location;

	if (/app.copyandpost.com$/.test(hostname)) {
		return Environment.Production;
	}

	if (/dev.copyandpost.com$/.test(hostname)) {
		return Environment.Sandbox;
	}

	if (/test.copyandpost.com$/.test(hostname)) {
		return Environment.Staging;
	}

	if (/vercel.app$/.test(hostname)) {
		return Environment.Preview;
	}

	return Environment.Local;
};

const detectRedirectUri = (environment: any) => {
	if (
		environment === Environment.Production ||
		environment === Environment.Staging ||
		environment === Environment.Sandbox ||
		environment === Environment.Local
	) {
		return import.meta.env.VITE_AUTH0_LOGIN_REDIRECT_URI;
	}

	if (environment === Environment.Preview) {
		return `https://${import.meta.env.VITE_VERCEL_URL}`;
	}
};

export const environment = detectEnvironment();

export const redirectUri = detectRedirectUri(environment);
