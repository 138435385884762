import React, { useContext } from 'react';
import { Box, Typography, LinearProgress } from '@mui/material';
import '../../common/styles/masonryStyles.css';
import { GRID_BREAKPOINTS, NoPosts } from '../../common';
import Masonry from 'react-masonry-css';
import { ThisMonthsMediaContext } from '../context';
import {
	useInfiniteScroller,
	useLoadItems
} from 'src/packages/lib/infinite-scroll';
import { PaywallContext } from 'src/packages/paywall';
import { MediaCard } from '../../common';

const PostBrowseResults = () => {
	let numberOfPosts: number[] = [];
	const { posts } = useContext(ThisMonthsMediaContext);
	const { isLoadingUserSubscriptionDetails } = useContext(PaywallContext);
	const { loading, items, hasNextPage, error, loadMore } = useLoadItems(posts);
	const infiniteRef = useInfiniteScroller(loading, hasNextPage, loadMore, error);

	posts?.forEach((category) =>
		numberOfPosts.push(category?.posts?.edges?.length)
	);

	if (posts?.length === 0) {
		return <NoPosts />;
	}

	return (
		<div>
			<Box
				sx={{
					alignItems: 'center',
					display: 'flex',
					flexWrap: 'wrap',
					justifyContent: 'space-between',
					mb: 2
				}}
			>
				<Typography
					color="textPrimary"
					sx={{
						mb: '10px',
						position: 'relative',
						'&:after': {
							backgroundColor: 'primary.main',
							bottom: '-8px',
							content: '" "',
							height: '3px',
							left: 0,
							position: 'absolute',
							width: '48px'
						}
					}}
					variant="h5"
				>
					Showing {posts?.length} posts
				</Typography>
			</Box>
			<Masonry
				breakpointCols={GRID_BREAKPOINTS}
				className="my-masonry-grid"
				columnClassName="my-masonry-grid_column"
			>
				{items?.map((post) => (
					<div key={post?.id}>
						<MediaCard post={post} />
					</div>
				))}
			</Masonry>
			{isLoadingUserSubscriptionDetails && <LinearProgress />}
			{!isLoadingUserSubscriptionDetails && hasNextPage && (
				<div ref={infiniteRef}>
					<LinearProgress />
				</div>
			)}
		</div>
	);
};

export default PostBrowseResults;
