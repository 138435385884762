import useInfiniteScroll from 'react-infinite-scroll-hook';

export const useInfiniteScroller = (
	loading: boolean,
	hasNextPage: boolean,
	loadMoreFunc: () => void,
	error: Error
) => {
	const [infiniteRef] = useInfiniteScroll({
		loading,
		hasNextPage,
		onLoadMore: loadMoreFunc,
		// When there is an error, we stop infinite loading.
		// It can be reactivated by setting "error" state as undefined.
		disabled: !!error,
		// `rootMargin` is passed to `IntersectionObserver`.
		// We can use it to trigger 'onLoadMore' when the sentry comes near to become
		// visible, instead of becoming fully visible on the screen.
		rootMargin: '0px 0px 400px 0px'
	});

	return infiniteRef;
};
