import React, { useState } from 'react';
import { MediaContainerContext } from './MediaContainerContext';

export const MediaContainerProvider: React.FC = ({ children }) => {
	const [isMediaContainerOpen, setIsMediaContainerOpen] = useState(false);
	const [previewMediaView, togglePreviewMediaView] = useState(false);
	const [publishMediaView, togglePublishMediaView] = useState(false);

	return (
		<MediaContainerContext.Provider
			value={{
				isMediaContainerOpen,
				setIsMediaContainerOpen,
				previewMediaView,
				publishMediaView,
				togglePreviewMediaView,
				togglePublishMediaView
			}}
		>
			{children}
		</MediaContainerContext.Provider>
	);
};
