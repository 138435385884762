import { Environment, environment } from '../environment/environment';

export const AUTH0_API = {
	[Environment.Local]: 'https://dev-zhut9izo.us.auth0.com/',
	[Environment.Production]: 'https://app-copyandpost.us.auth0.com/'
}[environment];

export const API = {
	[Environment.Local]: 'http://localhost:3000/api/',
	[Environment.Sandbox]: 'https://dev.copyandpost.com/api/',
	[Environment.Preview]: `https://${import.meta.env.VITE_VERCEL_URL}/api/`,
	[Environment.Staging]: 'https://test.copyandpost.com/api/',
	[Environment.Production]: 'https://app.copyandpost.com/api/'
}[environment];
