import React, { useCallback, useContext } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button, Tooltip } from '@mui/material';
import { useSnackbar } from 'notistack';
import FolderOpen from 'src/packages/icons/FolderOpen';
import useSettings from 'src/packages/hooks/useSettings';
import { MediaUploadContext } from '../context';
import { PutObjectCommand } from '@aws-sdk/client-s3';
import { s3Client, REGION, BUCKET } from '../config';

interface ImageUploadButtonProps {
	variant?: 'outlined' | 'text' | 'contained';
	tooltipPlacement?:
		| 'left'
		| 'bottom-end'
		| 'bottom-start'
		| 'bottom'
		| 'left-end'
		| 'left-start'
		| 'right-end'
		| 'right-start'
		| 'right'
		| 'top-end'
		| 'top-start'
		| 'top';
	styles?: any;
}

export const ImageUploadButton = ({
	variant = 'outlined',
	tooltipPlacement = 'left',
	styles
}: ImageUploadButtonProps) => {
	const { setCustomMediaUrl, setIsUploadingMedia } =
		useContext(MediaUploadContext);
	const { enqueueSnackbar } = useSnackbar();
	const { settings } = useSettings();

	const uploadFile = async (file) => {
		const command = new PutObjectCommand({
			Bucket: BUCKET,
			Key: file.name,
			Body: file,
			ContentType: file.type
		});

		try {
			setIsUploadingMedia(true);
			const response = await s3Client.send(command);
			const src = `https://${BUCKET}.s3.${REGION}.amazonaws.com/${encodeURIComponent(
				file.name
			)}`;
			setCustomMediaUrl(src);
			setIsUploadingMedia(false);
		} catch (error) {
			enqueueSnackbar('There was a problem uploading your file.', {
				anchorOrigin: {
					horizontal: 'right',
					vertical: 'top'
				},
				variant: 'error'
			});
			setIsUploadingMedia(false);
		}
	};

	const onDrop = useCallback((acceptedFiles) => {
		acceptedFiles.forEach((file) => {
			const reader = new FileReader();

			reader.onabort = () => console.log('file reading was aborted');
			reader.onerror = () => console.log('file reading has failed');
			reader.onload = () => {
				uploadFile(file);
			};
			reader.readAsArrayBuffer(file);
		});
	}, []);

	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		maxFiles: 1,
		accept: {
			'image/png': ['.png'],
			'image/jpg': ['.jpg'],
			'image/jpeg': ['.jpeg'],
			'image/gif': ['.gif']
		}
	});

	return (
		<>
			<Tooltip
				title="File must be of type .jpeg, .jpg, .png or .gif"
				placement={tooltipPlacement}
			>
				<Button
					size="large"
					variant={variant}
					color={settings.theme === 'DARK' ? 'primary' : 'inherit'}
					endIcon={<FolderOpen />}
					{...getRootProps()}
					sx={{ ...styles }}
				>
					<input {...getInputProps()} />
					Upload Custom Image
				</Button>
			</Tooltip>
		</>
	);
};
