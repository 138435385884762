import React, { useState, useContext } from 'react';
import { useSnackbar } from 'notistack';
import {
	postToFacebook,
	postVideoToFacebook,
	prepareUnpublishedPost
} from '../../services';
import { FacebookContext } from './FacebookContext';
import { CalendarContext } from 'src/packages/calendar/context';
import { useUserStore } from 'src/packages/state';
import { useAuth0 } from '@auth0/auth0-react';

export const FacebookProvider: React.FC = ({ children }) => {
	const [isPosting, setIsPosting] = useState(false);
	const { enqueueSnackbar } = useSnackbar();
	const { fetchAllScheduledPosts } = useContext(CalendarContext);
	const { userDetails, facebookPages } = useUserStore();
	const { user } = useAuth0();

	const handleResponse = (res) => {
		if (res.status === 200) {
			setIsPosting(false);
			enqueueSnackbar('Successful Facebook post!', {
				anchorOrigin: {
					horizontal: 'right',
					vertical: 'top'
				},
				variant: 'success'
			});
		}
		if (res.status === 'error') {
			enqueueSnackbar(res.statusText, {
				anchorOrigin: {
					horizontal: 'right',
					vertical: 'top'
				},
				variant: 'error'
			});
			setIsPosting(false);
		}
	};

	const handleFacebookVideoPost = async (
		pageId,
		access_token,
		encodedCaption,
		imgUrl
	) => {
		const res = await postVideoToFacebook(
			pageId,
			access_token,
			`${encodedCaption}`,
			imgUrl
		);
		setIsPosting(false);
		return handleResponse(res);
	};

	const handleFacebookPost = async (
		pageId,
		access_token,
		encodedCaption,
		imgUrl
	) => {
		const isGif = imgUrl.split('.').pop() === 'gif';

		// need to call a different endpoint to post animated gifs
		if (isGif) {
			return handleFacebookVideoPost(pageId, access_token, encodedCaption, imgUrl);
		}

		const res = await postToFacebook(
			pageId,
			access_token,
			`${encodedCaption}`,
			imgUrl
		);
		setIsPosting(false);
		return handleResponse(res);
	};

	const handleScheduledFacebookPost = async (
		pageId,
		access_token,
		encodedCaption,
		unixTimestamp,
		pageName,
		imgUrl
	) => {
		const isGif = imgUrl.split('.').pop() === 'gif';

		// need to call a different endpoint to post animated gifs
		if (isGif) {
			const res = await postVideoToFacebook(
				pageId,
				access_token,
				`${encodedCaption}`,
				imgUrl,
				user.sub,
				pageName,
				unixTimestamp
			);
			fetchAllScheduledPosts(userDetails);
			setIsPosting(false);
			return handleResponse(res);
		}

		// const preparePostRes = (await prepareUnpublishedPost(
		// 	pageId,
		// 	access_token,
		// 	imgUrl,
		// 	`${encodedCaption}`
		// )) as any;

		// if (preparePostRes.status === 200) {
		const scheduleToDbRes = await postToFacebook(
			pageId,
			access_token,
			`${encodedCaption}`,
			imgUrl,
			user.sub,
			pageName,
			unixTimestamp
		);
		// }

		// refetch scheduled posts for the Calendar view
		fetchAllScheduledPosts(userDetails);
		setIsPosting(false);
		return handleResponse(scheduleToDbRes);
	};

	return (
		<FacebookContext.Provider
			value={{
				handleFacebookPost,
				handleScheduledFacebookPost,
				isPosting,
				facebookPages
			}}
		>
			{children}
		</FacebookContext.Provider>
	);
};
