export const THUMBNAIL_COLORS = {
	FACEBOOK: '#3b5998',
	LINKEDIN: '#0072b1',
	INSTAGRAM: '#E1306C',
	FAILED: '#f44336',
	SUCCESS: '#1f6a5c'
};

export interface CalendarEvent {
	id?: string;
	allDay: boolean;
	color?: string;
	description: string;
	end: Date;
	start: Date;
	title: string;
	submit?: boolean;
	status?: string;
}

export type CalendarView =
	| 'dayGridMonth'
	| 'timeGridWeek'
	| 'timeGridDay'
	| 'listWeek';
