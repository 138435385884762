import { Response, Item, ARRAY_SIZE } from '../types';

export function loadItems(
	startCursor = 0,
	items: any[],
	startSlice: number,
	endSlice: number
): Response {
	let newArray: Item[] = [];

	for (let i = startCursor; i < startCursor + ARRAY_SIZE; i++) {
		newArray = [...items.slice(startSlice, endSlice)];
	}

	return { hasNextPage: endSlice <= items.length, data: newArray };
}
