import { gql } from '@apollo/client';

const limitedContentQuery = gql`
	query GetPostsByCategoryNames($names: [String]) {
		categories(where: { name: $names }) {
			nodes {
				id
				posts(first: 500, where: { orderby: { field: DATE, order: DESC } }) {
					edges {
						node {
							databaseId
							featuredPost {
								suggestedpostdate
								postimage {
									mediaItemUrl
								}
							}
							categories {
								nodes {
									name
								}
							}
							tags {
								edges {
									node {
										name
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;

/**
 * Gets all posts by categories
 * @param names: array of string category names to pull posts from. ie: ["Real Estate Terms", "Favorite Things"]
 */
const protectedContentQuery = gql`
	query GetPostsByCategoryNames($names: [String]) {
		categories(where: { name: $names }) {
			nodes {
				id
				posts(first: 500, where: { orderby: { field: DATE, order: DESC } }) {
					edges {
						node {
							databaseId
							featuredPost {
								popularhashtags
								canvalink
								recommendedcaption
								suggestedpostdate
								postimage {
									mediaItemUrl
								}
							}
							categories {
								nodes {
									name
								}
							}
							tags {
								edges {
									node {
										name
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;


export const getPostsByCategoryNames = (hasActiveSubscription) => {
	return hasActiveSubscription ? protectedContentQuery : limitedContentQuery;
}
