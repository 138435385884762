import { State } from './types';
import {
	getUser,
	updateUserCaptionSettings,
	initiatePasswordReset,
	updateUserStylePreference
} from '../services/auth0';
import create from 'zustand';
import {
	getInstagramPages,
	getPages,
	deleteFacebookPage,
	deleteInstagramPage
} from '../services/social';

interface ProfilesObject {
	[key: string]: any;
}

export const useUserStore = create<State>((set, get) => ({
	isLoadingUserSubscriptionDetails: true,
	pricingModal: false,
	facebookPages: [],
	instagramPages: [],
	setPricingModal: (value: boolean) => {
		set({ pricingModal: value });
	},
	setStylePreference: async (userId: string, value: any) => {
		set({ isLoading: true });

		const updateUserStylePreferenceResponse = await updateUserStylePreference(
			userId,
			value
		);

		if (updateUserStylePreferenceResponse.status !== 200) {
			return set((state) => {
				return {
					...state,
					isLoading: false,
					error: true
				};
			});
		}

		const getUserResponse = await getUser(userId);

		set((state) => {
			return {
				...state,
				isLoading: false,
				userDetails: getUserResponse.data,
				stylePreference: getUserResponse?.data?.user_metadata?.stylePreference,
				response: updateUserStylePreferenceResponse
			};
		});
	},
	getUserDetails: async (userId: string) => {
		set({ isLoading: true });

		const response = await getUser(userId);

		if (response.status !== 200 || response.data.name === 'Error') {
			return;
		}

		const profilesObject: ProfilesObject = {};
		response?.data?.identities?.forEach((identity) => {
			const user_id = identity.user_id.split('|')[1];
			if (identity.connection.includes('facebook')) {
				profilesObject['facebook'] = { ...identity, user_id, platform: 'facebook' };
			}
			if (identity.connection.includes('google')) {
				profilesObject['google'] = { ...identity, user_id, platform: 'google' };
			}
			if (identity.connection.includes('linkedin')) {
				profilesObject['linkedin'] = {
					...identity,
					...identity.profileData,
					platform: 'linkedin',
					name: response.data.name,
					user_id,
					picture: response?.data?.picture
				};
			}
		});

		set((state) => {
			return {
				...state,
				isLoading: false,
				userDetails: response?.data,
				connectedUserProfiles: profilesObject,
				linkedInPages: [profilesObject.linkedin].filter(Boolean),
				stylePreference: response?.data?.user_metadata?.stylePreference
					? response.data.user_metadata.stylePreference
					: false
			};
		});

		return response.data;
	},
	getInstagramPages: async (facebookUserId: string, accessToken: string) => {
		set({ isLoading: true });
		const instagramResponse = await getInstagramPages(
			facebookUserId,
			accessToken
		);

		if (instagramResponse.status === 'error') {
			set({
				instagramPages: [],
				instagramPagesError: instagramResponse.statusText,
				isLoading: false
			});

			return instagramResponse;
		}

		const instagramPages = instagramResponse?.data?.data;

		return set({
			instagramPages: instagramPages || [],
			isLoading: false
		});
	},
	getFacebookPages: async (facebookUserId: string) => {
		set({ isLoading: true });

		const auth0ConnectionId = `oauth2|custom-facebook-connection|${facebookUserId}`;

		const facebookResponseFromDB = await getPages(
			facebookUserId,
			auth0ConnectionId
		);
		const facebookPages = facebookResponseFromDB.data.facebookPages;
		const instagramPages = facebookResponseFromDB.data.instagramPages;

		if (facebookResponseFromDB.status === 'error') {
			set({
				facebookPages: [],
				facebookPagesError: facebookResponseFromDB.statusText,
				isLoading: false
			});
			return facebookResponseFromDB;
		}

		return set({
			facebookPages: facebookPages || [],
			instagramPages: instagramPages || [],
			isLoading: false
		});
	},
	deleteFacebookPage: async (
		id: number,
		facebookPageId: string,
		facebookUserId: string
	) => {
		set({ isLoading: true });
		const deleteResponse = await deleteFacebookPage(
			id,
			facebookPageId,
			facebookUserId
		);

		if (deleteResponse.status === 'error') {
			return set({
				error: true,
				isLoading: false
			});
		}

		return set({
			facebookPages: deleteResponse.data.facebookPages,
			isLoading: false
		});
	},
	deleteInstagramPage: async (
		id: number,
		facebookPageId: string,
		facebookUserId: string,
		instagramAccountId: string
	) => {
		set({ isLoading: true });
		const deleteResponse = await deleteInstagramPage(
			id,
			facebookPageId,
			facebookUserId,
			instagramAccountId
		);

		if (deleteResponse.status === 'error') {
			return set({
				error: true,
				isLoading: false
			});
		}

		return set({
			instagramPages: deleteResponse.data.instagramPages,
			isLoading: false
		});
	},
	updateUserCaptionSettings: async (userId: string, captionSettings) => {
		set({ isLoading: true });

		const response = await updateUserCaptionSettings(userId, captionSettings);
		const getUserResponse = await getUser(userId);

		set((state) => {
			return {
				...state,
				isLoading: false,
				userDetails: getUserResponse.data,
				response
			};
		});
	},
	initiatePasswordReset: async (userEmail: string) => {
		set({ isLoading: true });
		const response = await initiatePasswordReset(userEmail);

		set((state) => {
			return {
				...state,
				isLoading: false,
				response
			};
		});

		return response;
	}
}));
