import { convertDateToUnix } from './convertDateToUnix';
import { THUMBNAIL_COLORS } from '../types';

export const augmentInstagramActions = (instagramActions, status) => {
	const actions = [];

	instagramActions?.forEach((action) => {
		const unixTimestamp = convertDateToUnix(action.scheduled_time);
		actions.push({
			id: `${action.id}`,
			description: action.create_media_container_params.params.caption,
			platform: 'instagram',
			allDay: false,
			end: action.scheduled_time,
			start: action.scheduled_time,
			color: THUMBNAIL_COLORS.SUCCESS,
			title: action.page_name,
			post: {
				...action.create_media_container_params,
				full_picture: action.create_media_container_params.params.image_url,
				scheduled_publish_time: unixTimestamp * 1000
			},
			status
		});
	});

	return actions;
};
