import { convertDateToUnix } from './convertDateToUnix';
import { THUMBNAIL_COLORS } from '../types';

export const augmentFacebookActions = (facebookActions, status) => {
	const actions = [];

	facebookActions?.forEach((action) => {
		const unixTimestamp = convertDateToUnix(action.scheduled_time);
		actions.push({
			postLink: `https://facebook.com/${action.response.post_id}`,
			id: `${action.id}`,
			description:
				action.create_media_container_params.params.caption ||
				action.create_media_container_params.params.description,
			platform: 'facebook',
			allDay: false,
			end: action.scheduled_time,
			start: action.scheduled_time,
			color: THUMBNAIL_COLORS.SUCCESS,
			title: action.page_name,
			post: {
				...action.create_media_container_params,
				access_token: action.access_token,
				full_picture:
					action.create_media_container_params.params.url ||
					action.create_media_container_params.params.file_url,
				scheduled_publish_time: unixTimestamp * 1000
			},
			status
		});
	});

	return actions;
};
