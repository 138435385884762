import axios from 'axios';
import { API } from '../../../lib/api/api';

export const getInstagramPages = async (
	facebookUserId: string,
	accessToken: string
) => {
	try {
		return await axios.get(
			`${API}instagram/getProfiles/${facebookUserId}?userId=${facebookUserId}&accessToken=${accessToken}`
		);
	} catch (e) {
		return {
			error: e,
			status: 'error',
			statusText: 'There was a problem retrieving your connected Instagram pages.',
			data: e
		};
	}
};
