import { createContext } from 'react';

interface CalendarContext {
	fetchScheduledPosts?: any;
	scheduledPosts?: any;
	setScheduledPosts?: any;
	isFetchingScheduledPosts?: boolean;
	setIsFetchingScheduledPosts?: any;
	fetchScheduledLinkedInPosts?: any;
	fetchScheduledFacebookPosts?: any;
	fetchScheduledInstagramPosts?: any;
	fetchAllScheduledPosts?: any;
	scheduledFacebookPosts?: any;
	scheduledLinkedInPosts?: any;
	scheduledInstagramPosts?: any;
	failedPosts?: any;
	setSuccessfulPosts?: any;
	successfulPosts?: any;
	setViewPublishedPosts?: any;
	viewPublishedPosts?: any;
}

export const CalendarContext = createContext<CalendarContext>(null);
