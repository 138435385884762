import React, { useContext, useState } from 'react';
import { Theme, withStyles } from '@mui/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { Box } from '@mui/material';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import { PaywallContext } from '../context/';
import TrialPic from 'src/assets/trial-pic.png';
import useSettings from 'src/packages/hooks/useSettings';

const DialogContent = withStyles((theme: Theme) => ({
	root: {
		padding: theme.spacing(2),
		textAlign: 'center'
	}
}))(MuiDialogContent);

export const FreeTrialTeaserDialog = () => {
	// TODO: change this to use CSS not JS...
	const [buttonStyle, setButtonStyle] = useState<
		'text' | 'outlined' | 'contained'
	>('outlined');
	const {
		isFreeTrialTeaserDialogOpen,
		setIsFreeTrialTeaserDialogOpen,
		setIsChoosePlanDialogOpen
	} = useContext(PaywallContext);
	const { settings } = useSettings();
	return (
		<>
			<Dialog
				aria-labelledby="teaser-dialog"
				open={isFreeTrialTeaserDialogOpen}
				fullWidth={true}
				maxWidth={'lg'}
				onClose={() => {
					setIsFreeTrialTeaserDialogOpen(false);
				}}
			>
				<DialogContent dividers>
					<img
						onMouseEnter={() => setButtonStyle('contained')}
						onMouseLeave={() => setButtonStyle('outlined')}
						onClick={() => setIsChoosePlanDialogOpen(true)}
						style={{ cursor: 'pointer' }}
						width="100%"
						src={TrialPic}
					/>
					<Box display="flex">
						<Button
							sx={{ fontSize: '1rem', width: '20%', marginRight: '1rem' }}
							variant={settings.theme === 'LIGHT' ? 'outlined' : 'contained'}
							onClick={() => setIsFreeTrialTeaserDialogOpen(false)}
						>
							Go Back
						</Button>
						<Button
							sx={{ fontSize: '1rem', width: '80%' }}
							variant={buttonStyle}
							onClick={() => setIsChoosePlanDialogOpen(true)}
						>
							Unlock Your 14 Day Free Trial Now
						</Button>
					</Box>
				</DialogContent>
			</Dialog>
		</>
	);
};
