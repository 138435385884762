import { gql } from '@apollo/client';

// used in ContentByCategory
const limitedContentQuery = gql`
query GetPostsByTag($parentId: ID!, $tagSlugIn: [String]) {
	category(id: $parentId, idType: SLUG) {
		children {
			edges {
				node {
					children(first: 50) {
						nodes {
							name
							id
							posts(
								first: 1000
								where: { tagSlugIn: $tagSlugIn, orderby: { field: DATE, order: DESC } }
							) {
								edges {
									node {
										id
										featuredPost {
											suggestedpostdate
											postimage {
												mediaItemUrl
												title
											}
										}
										databaseId
										tags {
											edges {
												node {
													name
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
`;



/**
 * Gets posts that where post tags is included in array of tags passed
 * @param parentId - parent category to search within
 * @param tagSlugIn - array of string tag names ie: ['open house' , 'listing']
 */
const protectedContentQuery = gql`
	query GetPostsByTag($parentId: ID!, $tagSlugIn: [String]) {
		category(id: $parentId, idType: SLUG) {
			children {
				edges {
					node {
						children(first: 50) {
							nodes {
								name
								id
								posts(
									first: 1000
									where: { tagSlugIn: $tagSlugIn, orderby: { field: DATE, order: DESC } }
								) {
									edges {
										node {
											id
											featuredPost {
												popularhashtags
												recommendedcaption
												suggestedpostdate
												canvalink
												postimage {
													mediaItemUrl
													title
												}
											}
											databaseId
											tags {
												edges {
													node {
														name
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;

export const getPostsByTags = (hasActiveSubscription) => {
	return hasActiveSubscription ? protectedContentQuery : limitedContentQuery;
}