import { useRef, useState } from 'react';
import type { FC } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
	Avatar,
	Box,
	Button,
	ButtonBase,
	Divider,
	ListItemIcon,
	ListItemText,
	MenuItem,
	Popover,
	Typography,
	Link
} from '@mui/material';
import EyeIcon from 'src/packages/icons/Eye';
import UserIcon from 'src/packages/icons/User';
import ClipboardList from 'src/packages/icons/ClipboardList';
import DocumentText from 'src/packages/icons/DocumentText';
import InformationCircle from 'src/packages/icons/InformationCircle';
import Lock from 'src/packages/icons/Lock';
import CogIcon from '../../icons/Cog';
import { useAuth0 } from '@auth0/auth0-react';
import { auth0Config } from '../../../config';

const AccountPopover: FC = () => {
	const anchorRef = useRef<HTMLButtonElement | null>(null);
	const { user, logout } = useAuth0();
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();
	const [open, setOpen] = useState<boolean>(false);

	const handleOpen = (): void => {
		setOpen(true);
	};

	const handleClose = (): void => {
		setOpen(false);
	};

	const handleLogout = async (): Promise<void> => {
		try {
			handleClose();
			await logout({ returnTo: auth0Config.logoutRedirectUri });
			navigate('/');
		} catch (err) {
			console.error(err);
			enqueueSnackbar('Unable to logout', {
				anchorOrigin: {
					horizontal: 'right',
					vertical: 'top'
				},
				variant: 'error'
			});
		}
	};

	return (
		<>
			<Box
				component={ButtonBase}
				onClick={handleOpen}
				ref={anchorRef}
				sx={{
					alignItems: 'center',
					display: 'flex'
				}}
			>
				<Avatar
					src={user.picture}
					sx={{
						height: 32,
						width: 32
					}}
				/>
			</Box>
			<Popover
				anchorEl={anchorRef.current}
				anchorOrigin={{
					horizontal: 'center',
					vertical: 'bottom'
				}}
				getContentAnchorEl={null}
				keepMounted
				onClose={handleClose}
				open={open}
				PaperProps={{
					sx: { width: 240 }
				}}
			>
				<Box sx={{ p: 2 }}>
					<Typography color="textPrimary" variant="subtitle2">
						{user.name}
					</Typography>
					<Typography color="textSecondary" variant="subtitle2">
						Copy and Post
					</Typography>
				</Box>
				<Divider />
				<Box sx={{ mt: 2 }}>
					<MenuItem component={RouterLink} to="/dashboard/branding/captionSettings">
						<ListItemIcon>
							<EyeIcon fontSize="small" />
						</ListItemIcon>
						<ListItemText
							primary={
								<Typography color="textPrimary" variant="subtitle2">
									Branding Center
								</Typography>
							}
						/>
					</MenuItem>
					<MenuItem component={RouterLink} to="/dashboard/account/profile">
						<ListItemIcon>
							<UserIcon fontSize="small" />
						</ListItemIcon>
						<ListItemText
							primary={
								<Typography color="textPrimary" variant="subtitle2">
									Account
								</Typography>
							}
						/>
					</MenuItem>
					<MenuItem component={RouterLink} to="/dashboard/help-support">
						<ListItemIcon>
							<InformationCircle fontSize="small" />
						</ListItemIcon>
						<ListItemText
							primary={
								<Typography color="textPrimary" variant="subtitle2">
									Help and Support
								</Typography>
							}
						/>
					</MenuItem>
					<MenuItem>
						<ListItemIcon>
							<Lock fontSize="small" />
						</ListItemIcon>
						<Link
							href="https://copyandpost.com/privacy-policy"
							variant="body2"
							target="_blank"
							color="textPrimary"
							sx={{
								paddingTop: '6px',
								paddingBottom: '6px'
							}}
						>
							Privacy Policy
						</Link>
					</MenuItem>
					<MenuItem>
						<ListItemIcon>
							<DocumentText fontSize="small" />
						</ListItemIcon>
						<Link
							color="textPrimary"
							href="https://copyandpost.com/company-terms"
							variant="body2"
							target="_blank"
							sx={{
								paddingTop: '6px',
								paddingBottom: '6px'
							}}
						>
							Terms and Conditions
						</Link>
					</MenuItem>
				</Box>
				<Box sx={{ p: 2 }}>
					<Button
						color="primary"
						fullWidth
						onClick={handleLogout}
						variant="outlined"
					>
						Logout
					</Button>
				</Box>
			</Popover>
		</>
	);
};

export default AccountPopover;
