import { gql } from '@apollo/client';

/**
 * Gets all posts by a category
 * @param categoryIn: array of category IDs to pull posts from. ie: [113, 110]
 */
export const GET_POSTS_BY_CATEGORY_IDS = gql`
	query GetPostsByCategoryIds($categoryIn: [ID]) {
		posts(first: 1000, where: { categoryIn: $categoryIn }) {
			edges {
				node {
					id
					featuredPost {
						popularhashtags
						recommendedcaption
						suggestedpostdate
						canvalink
						postimage {
							mediaItemUrl
							title
						}
					}
					databaseId
					tags {
						edges {
							node {
								name
							}
						}
					}
					categories {
						nodes {
							name
							id
							databaseId
						}
					}
				}
			}
		}
	}
`;
