export const createDownloadOptionsArray = (data) => {
	const optionsArray = [];

	const extractOptions = (source) => {
		for (const key in source) {
			if (source.hasOwnProperty(key) && key !== '__typename') {
				const title = key.replace(/^demoMatching|^demoMonthly/, ''); // Extracting title from the key
				const link = source[key];

				optionsArray.push({ title: `${title} Collection`, link });
			}
		}
	};

	extractOptions(data);

	return optionsArray;
};
