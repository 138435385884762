import React, { useEffect, useState, FC } from 'react';
import { CalendarContext } from './CalendarContext';
import {
	getFailedPosts,
	getAllScheduledPosts,
	getSuccessfulPosts
} from '../../services';
import {
	augmentFacebookActions,
	augmentInstagramActions,
	augmentLinkedInActions
} from '../utils';
import { useUserStore } from '../../state';
import { convertDateToUnix } from '../utils/convertDateToUnix';
import { useTheme } from '@mui/material';

interface EventPost {
	id: string;
	description: string;
	platform: string;
	allDay: false;
	start: number;
	end: number;
	color: string;
	title: string;
	post?: any;
}

export const CalendarProvider: FC = ({ children }) => {
	const { userDetails } = useUserStore();
	const theme = useTheme();
	const [isFetchingScheduledPosts, setIsFetchingScheduledPosts] =
		useState(false);
	const [successfulPosts, setSuccessfulPosts] = useState(null);
	const [viewPublishedPosts, setViewPublishedPosts] = useState(true);
	const [failedPosts, setFailedPosts] = useState({ events: [] });
	const [scheduledPosts, setScheduledPosts] = useState<any>({ events: [] });

	const fetchAllScheduledPosts = async (userDetails) => {
		setIsFetchingScheduledPosts(true);
		const fetchedScheduledPosts = await getAllScheduledPosts(userDetails.user_id);
		const failedPosts = await fetchAllFailedPosts(userDetails.user_id);
		const successfulPosts = await fetchAllSuccessfulPosts(userDetails.user_id);
		setScheduledPosts({
			events: [
				...fetchedScheduledPosts.data.augmentedInstagramActions,
				...fetchedScheduledPosts.data.augmentedLinkedInActions,
				...fetchedScheduledPosts.data.augmentedFacebookActions,
				...failedPosts,
				...(viewPublishedPosts ? successfulPosts : [])
			]
		});
		setIsFetchingScheduledPosts(false);
	};

	useEffect(() => {
		userDetails && fetchAllScheduledPosts(userDetails);
	}, [userDetails]);

	const fetchAllSuccessfulPosts = async (user) => {
		const fetchedSuccessfulPosts = await getSuccessfulPosts(user);
		const facebookSuccesses = fetchedSuccessfulPosts?.data?.facebookSuccess;
		const instagramSuccesses = fetchedSuccessfulPosts?.data?.instagramSuccess;
		const linkedinSuccesses = fetchedSuccessfulPosts?.data?.linkedinSuccess;
		const augmentedInstagramSuccesses = augmentInstagramActions(
			instagramSuccesses,
			'success'
		);
		const augmentedLinkedinSuccesses = augmentLinkedInActions(
			linkedinSuccesses,
			'success'
		);
		const augmentedFacebookSuccesses = augmentFacebookActions(
			facebookSuccesses,
			'success'
		);
		const allSuccessfulPosts = [
			...augmentedFacebookSuccesses,
			...augmentedLinkedinSuccesses,
			...augmentedInstagramSuccesses
		];
		setSuccessfulPosts(allSuccessfulPosts);
		return allSuccessfulPosts;
	};

	const fetchAllFailedPosts = async (user) => {
		const fetchedFailedPosts = await getFailedPosts(user);
		const failedFBPosts = fetchedFailedPosts?.data?.facebookFailures?.map(
			(failedPost) => {
				const unixTimestamp = convertDateToUnix(failedPost.scheduled_time);
				return {
					id: failedPost.id + ':FB',
					description: failedPost.create_media_container_params.params.caption,
					allDay: false,
					end: failedPost.scheduled_time,
					start: failedPost.scheduled_time,
					title: failedPost.page_name,
					platform: 'facebook',
					color: theme.palette.error.dark,
					status: 'failed',
					post: {
						full_picture: failedPost.create_media_container_params.params.url,
						scheduled_publish_time: unixTimestamp * 1000
					}
				};
			}
		);

		const failedIGPosts = fetchedFailedPosts?.data?.instagramFailures?.map(
			(failedPost) => {
				const unixTimestamp = convertDateToUnix(failedPost.scheduled_time);
				return {
					id: failedPost.id + ':IG',
					description: failedPost.create_media_container_params.params.caption,
					allDay: false,
					end: failedPost.scheduled_time,
					start: failedPost.scheduled_time,
					title: failedPost.page_name,
					platform: 'instagram',
					color: theme.palette.error.dark,
					status: 'failed',
					post: {
						full_picture: failedPost.create_media_container_params.params.image_url,
						scheduled_publish_time: unixTimestamp * 1000
					}
				};
			}
		);

		const failedLKPosts = fetchedFailedPosts?.data?.linkedinFailures?.map(
			(failedPost) => {
				const unixTimestamp = convertDateToUnix(failedPost.scheduled_time);
				return {
					id: failedPost.id + ':LK',
					description: failedPost.caption,
					allDay: false,
					end: failedPost.scheduled_time,
					start: failedPost.scheduled_time,
					title: failedPost.name,
					platform: 'linkedin',
					color: theme.palette.error.dark,
					status: 'failed',
					post: {
						full_picture: failedPost.media_url,
						scheduled_publish_time: unixTimestamp * 1000
					}
				};
			}
		);

		setFailedPosts({
			events: [...failedFBPosts, ...failedIGPosts, ...failedLKPosts]
		});
		return [...failedFBPosts, ...failedIGPosts, ...failedLKPosts];
	};

	return (
		<CalendarContext.Provider
			value={{
				isFetchingScheduledPosts,
				setIsFetchingScheduledPosts,
				fetchAllScheduledPosts,
				failedPosts,
				setScheduledPosts,
				scheduledPosts,
				setSuccessfulPosts,
				successfulPosts,
				viewPublishedPosts,
				setViewPublishedPosts
			}}
		>
			{children}
		</CalendarContext.Provider>
	);
};
