import React, { useContext, useEffect } from 'react';
import type { FC } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { makeStyles } from '@mui/styles';
import {
	Box,
	Breadcrumbs,
	Container,
	Grid,
	LinearProgress,
	Link,
	Typography,
	Alert,
	AlertTitle
} from '@mui/material';
import PostBrowseFilter from './PostBrowseFilter';
import PostBrowseResults from './PostBrowseResults';
import useSettings from 'src/packages/hooks/useSettings';
import ChevronRightIcon from 'src/packages/icons/ChevronRight';
import { useUserStore } from 'src/packages/state';
import { ThisMonthsMediaContext } from '../context';
import { MediaBrowserContext } from 'src/packages/media-browser';
import { BackToTop } from '../../common';

const useStyles = makeStyles((theme) => {
	return {
		pageTitle: {
			[theme.breakpoints.down('md')]: {
				flexDirection: 'column',
				alignItems: 'flex-start'
			}
		},
		yearMonth: {
			textAlign: 'right',
			[theme.breakpoints.down('md')]: {
				textAlign: 'left'
			}
		}
	};
});

const ThisMonthsMedia: FC = () => {
	const { settings } = useSettings();
	const styles = useStyles();
	const navigate = useNavigate();
	const { addCategory, categories } = useContext(ThisMonthsMediaContext);
	const { localStorageKeys, getLocalStorageFilters } =
		useContext(MediaBrowserContext);
	const localStorageFilters = getLocalStorageFilters(
		localStorageKeys.thisMonthsMedia
	);
	const {
		stylePreference,
		userDetails,
		facebookPagesError,
		instagramPagesError,
		userSubscriptionDetailsError
	} = useUserStore();
	const isSocialUser =
		userDetails?.user_id.includes('google') ||
		userDetails?.user_id.includes('facebook') ||
		userDetails?.user_id.includes('linkedin');

	useEffect(() => {
		if (!localStorageFilters && stylePreference === 'none') {
			const categorySlug = categories[0] && categories[0].slug;
			addCategory({
				variables: { id: categorySlug, categoryName: 'this-month-media' }
			});
			return;
		}
		localStorageFilters &&
			addCategory({
				variables: {
					id: localStorageFilters.categoryNames[0],
					categoryName: 'this-month-media'
				}
			});
	}, [stylePreference, categories]);

	return (
		<>
			<Helmet>
				<title>Dashboard: This Month's Media | Copy and Post</title>
			</Helmet>
			<Box
				sx={{
					backgroundColor: 'background.default',
					minHeight: '100%',
					py: 8
				}}
			>
				<Container maxWidth={settings.compact ? 'xl' : false}>
					<BackToTop />
					<Grid
						alignItems="center"
						container
						justifyContent="space-between"
						spacing={3}
					>
						<Grid width="100%" item>
							<Grid
								display="flex"
								justifyContent="space-between"
								alignItems="end"
								className={styles.pageTitle}
							>
								<Typography
									id="page-title"
									color="textPrimary"
									sx={{ marginRight: '0.5rem' }}
									variant="h5"
								>
									This Month's Media
								</Typography>
								<Box>
									<Typography
										className={styles.yearMonth}
										color="textPrimary"
										variant="h6"
									>
										{new Date().toLocaleString('default', { month: 'long' })},{' '}
										{new Date().getFullYear()}
									</Typography>
									<Typography color="textPrimary" variant="body1" alignItems="baseline">
										New content added the 1st of every month
									</Typography>
								</Box>
							</Grid>
							<Breadcrumbs
								aria-label="breadcrumb"
								separator={<ChevronRightIcon fontSize="small" />}
								sx={{ mt: 1 }}
							>
								<Link
									color="textPrimary"
									component={RouterLink}
									to="/dashboard/this-months-media"
									variant="subtitle2"
								>
									Dashboard
								</Link>
								<Typography color="textPrimary" variant="subtitle2">
									Content and Captions
								</Typography>
								<Typography color="textSecondary" variant="subtitle2">
									This Month's Media
								</Typography>
							</Breadcrumbs>
						</Grid>
					</Grid>
					<>
						{stylePreference === 'none' && (
							<Box sx={{ mb: '1rem', mt: '1rem', cursor: 'pointer' }}>
								<Alert
									severity="info"
									onClick={() => {
										navigate('/dashboard/branding/designChoice');
									}}
								>
									<AlertTitle>
										You have not selected a custom collection choice. Click here to choose
										one.
									</AlertTitle>
								</Alert>
							</Box>
						)}
						{userDetails && !userDetails.email_verified && !isSocialUser && (
							<Box sx={{ mb: '1rem', mt: '1rem' }}>
								<Alert severity="warning">
									<AlertTitle>
										You still need to verify your email address. Check your email for a
										verification link.
									</AlertTitle>
								</Alert>
							</Box>
						)}
						{facebookPagesError && (
							<Box sx={{ mb: '1rem', mt: '1rem' }}>
								<Alert severity="error">
									<AlertTitle>{facebookPagesError}</AlertTitle>
								</Alert>
							</Box>
						)}
						{instagramPagesError && (
							<Box sx={{ mb: '1rem', mt: '1rem' }}>
								<Alert severity="error">
									<AlertTitle>{instagramPagesError}</AlertTitle>
								</Alert>
							</Box>
						)}
						{userSubscriptionDetailsError && (
							<Box sx={{ mb: '1rem', mt: '1rem' }}>
								<Alert severity="error">
									<AlertTitle>{userSubscriptionDetailsError}</AlertTitle>
								</Alert>
							</Box>
						)}
						<Box sx={{ mt: 3 }}>
							<PostBrowseFilter />
						</Box>
						<Box sx={{ mt: 6 }}>
							<PostBrowseResults />
						</Box>
					</>
				</Container>
			</Box>
		</>
	);
};

export default ThisMonthsMedia;
