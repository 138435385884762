import React, { useContext, useEffect, useState } from 'react';
import uniqBy from 'lodash.uniqby';
import { useQuery, useLazyQuery } from '@apollo/client';
import { MediaBrowserContext } from 'src/packages/media-browser';
import { ContentByCategoryContext } from './ContentByCategoryContext';
import { useUserStore } from 'src/packages/state';

export const ContentByCategoryProvider: React.FC = ({ children }) => {
	const {
		queries,
		getLocalStorageFilters,
		localStorageKeys,
		initLocalStorageFilters
	} = useContext(MediaBrowserContext);
	const [categories, setCategories] = useState([]);
	// track subcategory names in order to distinguish between TAGS and CATEGORIES
	const [subCats, setSubCats] = useState([]);
	const [multiSelectOptions, setMultiSelectOptions] = useState([]);
	const [posts, setPosts] = useState([]);
	const { stylePreference } = useUserStore();

	const localStorageFilters = getLocalStorageFilters(
		localStorageKeys.contentByCategory
	);

	useEffect(() => {
		if (!localStorageFilters && stylePreference) {
			const setStylePref =
				stylePreference === 'none' ? 'The Maverick' : stylePreference;
			initLocalStorageFilters(
				localStorageKeys.contentByCategory,
				JSON.stringify({
					categoryNames: [setStylePref],
					tags: [],
					chips: [setStylePref]
				})
			);
			addCategory({
				variables: { names: [stylePreference] }
			});
		}
	}, [localStorageFilters, stylePreference]);

	const handleSetMultiSelectOptions = (categories) => {
		const multiSelectOptions = [];
		const subCategories = [];
		categories?.forEach((category) => {
			const { name: label, children } = category.node;
			const options = children?.nodes?.map((node) => {
				subCategories.push(node.name);
				return { name: node.name, slug: node.slug, id: node.databaseId, ...node };
			});

			multiSelectOptions.push({
				label,
				options
			});
		});

		setMultiSelectOptions(multiSelectOptions);
		setSubCats(subCategories);
	};

	const removeCategory = (categoryName: string) => {
		const filteredPosts = posts.filter((post) => {
			const isTagged = post?.node?.tags?.edges?.some((tag) =>
				localStorageFilters.tags.includes(tag.node.name)
			);
			if (isTagged) {
				return post;
			}
			return post?.node?.categories?.nodes?.every(
				(cat) => cat.name !== categoryName
			);
		});
		setPosts(filteredPosts);
	};

	const removeTaggedPosts = (removedTag: string) => {
		const filteredPosts = posts.filter((post) => {
			return post?.node?.tags?.edges?.every(
				(tag) => tag.node.name.toLowerCase() !== removedTag.toLowerCase()
			);
		});
		setPosts(filteredPosts);
	};

	const handleAddCategory = (queryResult) => {
		const result = [];
		queryResult?.categories?.nodes?.forEach((node) =>
			result.push(...node.posts.edges)
		);
		const filteredPosts = uniqBy([...result, ...posts].flat(), 'node.databaseId');
		setPosts(filteredPosts);
	};

	const handleTagSearch = (queryResult) => {
		const result = [];
		queryResult?.category?.children?.edges?.forEach((category) => {
			category.node.children.nodes.forEach((subCategory) =>
				result.push(subCategory.posts.edges)
			);
		});
		const filteredResult = result
			.filter((postsArray) => postsArray.length > 0)
			.flat();
		const newPosts = uniqBy(
			[...filteredResult, ...posts].flat(),
			'node.databaseId'
		);
		setPosts(newPosts);
	};

	const [
		tagSearch,
		{
			called: calledTagSearch,
			loading: fetchingTaggedPosts,
			data: fetchedTaggedPosts
		}
	] = useLazyQuery(queries.GET_POSTS_BY_TAGS, {
		nextFetchPolicy: 'cache-first',
		onCompleted: (data) => handleTagSearch(data)
	});

	const [
		addCategory,
		{
			called: calledCategoryAdd,
			loading: fetchingCategory,
			data: fetchedCategory
		}
	] = useLazyQuery(queries.GET_POSTS_BY_CATEGORY_NAMES, {
		nextFetchPolicy: 'cache-first',
		onCompleted: (data) => handleAddCategory(data)
	});

	const { data: fetchedCategories, loading: isFetchingCategories } = useQuery(
		queries.GET_CHILD_CATS_BY_PARENT_CAT_SLUG,
		{
			variables: { parentId: 'content-by-categories' },
			nextFetchPolicy: 'cache-first',
			onCompleted: (data) => {
				const excludedCategorySlugs = ['this-month-media', 'next-month-media'];
				const augmentedCategories = data?.category?.children?.edges.filter(
					(cat) => {
						return !excludedCategorySlugs.includes(cat.node.slug);
					}
				);
				setCategories(augmentedCategories);
				handleSetMultiSelectOptions(augmentedCategories);
			}
		}
	);

	return (
		<ContentByCategoryContext.Provider
			value={{
				posts,
				setPosts,
				categories,
				subCats,
				addCategory,
				removeCategory,
				tagSearch,
				multiSelectOptions,
				removeTaggedPosts
			}}
		>
			{children}
		</ContentByCategoryContext.Provider>
	);
};
