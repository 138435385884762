import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { FacebookPermissionsContext } from '../FacebookPermissionsContext';
import { Box, Alert, AlertTitle } from '@mui/material';

export const DeclinedIGPermissionsMessage = () => {
	const { isMissingIGPermissions } = useContext(FacebookPermissionsContext);
	const navigate = useNavigate();
	return (
		<>
			{isMissingIGPermissions && (
				<Box sx={{ mb: '1rem', cursor: 'pointer' }}>
					<Alert
						severity="warning"
						onClick={() => {
							navigate('/dashboard/social-profiles');
						}}
					>
						<AlertTitle>
							Your Instagram profiles need to be reconnected.
							<br></br>
							<u>Manage your profiles.</u>
						</AlertTitle>
					</Alert>
				</Box>
			)}
		</>
	);
};
