import { gql } from '@apollo/client';

export const GET_ALL_CATEGORY_NAMES = gql`
	query GetCategoryEdges {
		categories(first: 100) {
			edges {
				node {
					id
					name
				}
			}
		}
	}
`;

// Query for getting the Style It categories
export const GET_STYLE_IT_CATEGORIES_BY_SLUG = gql`
	query GetStyleItCategoriesBySlug($id: ID!) {
		category(idType: SLUG, id: $id) {
			id
			children {
				nodes {
					name
					slug
					categoryFeaturedImage {
						categoryfeaturedimage {
							mediaItemUrl
						}
					}
					posts {
						edges {
							node {
								id
								featuredPost {
									postimage {
										mediaItemUrl
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;

// Query for getting all posts by a category slu
export const GET_POSTS_BY_CATEGORY_NAME = gql`
	query GetPostsByCategoryName($id: ID!) {
		category(id: $id, idType: NAME) {
			id
			name
			posts {
				edges {
					node {
						id
						featuredPost {
							popularhashtags
							recommendedcaption
							suggestedpostdate
							canvalink
							postimage {
								mediaItemUrl
							}
						}
					}
				}
			}
		}
	}
`;

export const GET_CHILD_CATEGORIES_BY_PARENT_ID = gql`
	query GetChildCategoriesByParentId($parentId: ID!) {
		category(id: $parentId, idType: SLUG) {
			id
			name
			slug
			children {
				edges {
					node {
						id
						name
						slug
					}
				}
			}
		}
	}
`;

export const GET_DESIGN_CHOICE_SLIDESHOW_PHOTOS = gql`
	query GetDesignChoiceSlideshowPhotos {
		tags(where: { nameLike: "slideshow" }) {
			nodes {
				id
				name
				contentNodes(first: 100) {
					nodes {
						... on Post {
							id
							featuredPost {
								postimage {
									mediaItemUrl
								}
							}
							categories {
								nodes {
									slug
								}
							}
						}
					}
				}
			}
		}
	}
`;
