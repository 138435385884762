import { useRef, useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import PlusIcon from 'src/packages/icons/Plus';

interface MultiSelectProps {
	label: string;
	slug: string;
	onChange?: (
		value: string[],
		categorySlug: string,
		selectedValue: string
	) => void;
	updatePosts?: (value: string[]) => void;
	value: string[];
}

const MultiSelect: FC<MultiSelectProps> = (props) => {
	const { label, onChange, updatePosts, value, slug, ...other } = props;
	const anchorRef = useRef<HTMLButtonElement | null>(null);
	const [openMenu, setOpenMenu] = useState<boolean>(false);

	const handleMenuOpen = (): void => {
		setOpenMenu(true);
	};

	const handleMenuClose = (): void => {
		setOpenMenu(false);
	};

	const handleOptionToggle = (label: string): void => {
		let newValue = [...value];

		if (label) {
			newValue.push(label);
		} else {
			newValue = newValue.filter((item) => item !== label);
		}

		if (onChange) {
			onChange(newValue, slug, label);
		}

		if (updatePosts) {
			updatePosts(newValue);
		}
	};

	return (
		<>
			<Button
				color="inherit"
				endIcon={<PlusIcon fontSize="small" />}
				sx={{
					marginRight: '0.6rem'
				}}
				onClick={() => handleOptionToggle(label)}
				ref={anchorRef}
				disabled={value.includes(label)}
				variant="text"
				{...other}
			>
				{label}
			</Button>
		</>
	);
};

MultiSelect.propTypes = {
	label: PropTypes.string.isRequired,
	onChange: PropTypes.func,
	value: PropTypes.array.isRequired
};

export default MultiSelect;
