import React, { useState, useContext, useEffect } from 'react';
import { createStyles, withStyles, WithStyles, makeStyles } from '@mui/styles';
import { Theme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import { useAuth0 } from '@auth0/auth0-react';
import { useStripe } from '@stripe/react-stripe-js';
import { useSnackbar } from 'notistack';
import PricingOptions from './PricingOptions';
import {
	createCheckoutSession,
	updateExistingCustomer
} from 'src/packages/services/stripe';
import { useSubscriptionPlanDetails } from 'src/packages/hooks';
import { getCookie } from 'src/packages/utils';
import { PaywallContext } from '../context';
import { getAffiliateDetails } from 'src/packages/services/affiliate/getAffiliateDetails';

const styles = (theme: Theme) =>
	createStyles({
		root: {
			margin: 0,
			padding: theme.spacing(2)
		},
		closeButton: {
			position: 'absolute',
			right: theme.spacing(1),
			top: theme.spacing(1),
			color: theme.palette.grey[500]
		},
		modal: {
			width: '1000px'
		}
	});

export interface DialogTitleProps extends WithStyles<typeof styles> {
	id: string;
	children: React.ReactNode;
	onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{children}</Typography>
		</MuiDialogTitle>
	);
});

const DialogContent = withStyles((theme: Theme) => ({
	root: {
		padding: theme.spacing(2)
	}
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(1)
	}
}))(MuiDialogActions);

const useStyles = makeStyles((theme: Theme) => {
	return createStyles({
		modalRoot: {
			maxWidth: '1000px'
		}
	});
});

export const SubscriptionModal = () => {
	const { isChoosePlanDialogOpen, setIsChoosePlanDialogOpen } =
		useContext(PaywallContext);
	const [open, setOpen] = React.useState(true);
	const [affiiateId, setAffiiateId] = React.useState(null);
	const [pricingSelection, setPricingSelection] = useState<string>(null);
	const { user } = useAuth0();
	const plan = useSubscriptionPlanDetails();
	const { enqueueSnackbar } = useSnackbar();
	const stripe = useStripe();
	const isAffiliateReferral = getCookie('tap_vid');
	const referralCode = getCookie('referral');

	useEffect(() => {
		const fetchAffiliateDetails = async (referralCode: string) => {
			const { data } = await getAffiliateDetails(referralCode);
			setAffiiateId(data.id);
		};
		referralCode && fetchAffiliateDetails(referralCode);
	}, [referralCode]);

	const handleUpdateExistingCustomer = async (stripePriceId: string) => {
		const response = await updateExistingCustomer(
			stripePriceId,
			user,
			plan.customerId
		);
		// if there is no sessionId returned, then we have an error
		if (response.status === 'error' || !response.data.sessionId) {
			enqueueSnackbar(
				'There was a problem. Please contact support if the issue persists.',
				{
					anchorOrigin: {
						horizontal: 'right',
						vertical: 'top'
					},
					variant: 'error'
				}
			);
			return;
		}
		stripe.redirectToCheckout({ sessionId: response.data.sessionId });
	};

	const handleCreateSessionCheckout = async (stripePriceId: string) => {
		const response = await createCheckoutSession(
			stripePriceId,
			user,
			isAffiliateReferral,
			affiiateId
		);
		// if there is no sessionId returned, then we have an error
		if (response.status === 'error' || !response.data.sessionId) {
			enqueueSnackbar(
				'There was a problem. Please contact support if the issue persists.',
				{
					anchorOrigin: {
						horizontal: 'right',
						vertical: 'top'
					},
					variant: 'error'
				}
			);
			return;
		}
		stripe.redirectToCheckout({ sessionId: response.data.sessionId });
	};

	return (
		<div>
			<Dialog
				aria-labelledby="subscription-modal"
				open={isChoosePlanDialogOpen}
				fullWidth={true}
				maxWidth={'sm'}
				onClose={() => setIsChoosePlanDialogOpen(false)}
			>
				<DialogContent dividers>
					<div style={{ textAlign: 'center' }}>
						<img src="/logoBlack.png" alt="logo" />
					</div>
					{plan.hasUsedFreeTrial && (
						<Typography marginTop="1rem" textAlign="center" variant="h5">
							Choose a plan to continue
						</Typography>
					)}
					<PricingOptions
						pricingSelection={pricingSelection}
						setPricingSelection={setPricingSelection}
					/>
					{plan.hasUsedFreeTrial && (
						<Button
							fullWidth
							color="primary"
							variant={'contained'}
							disabled={!pricingSelection}
							onClick={() => handleUpdateExistingCustomer(pricingSelection)}
						>
							Subscribe Now
						</Button>
					)}
					{!plan.hasUsedFreeTrial && (
						<>
							<Typography textAlign="center" variant="h5">
								Choose a plan to continue
							</Typography>
							<Typography
								sx={{ marginBottom: '1rem' }}
								textAlign="center"
								variant="subtitle1"
							>
								and get the first 14 days free
							</Typography>
							<Button
								fullWidth
								color="primary"
								variant={'contained'}
								disabled={!pricingSelection}
								onClick={() => handleCreateSessionCheckout(pricingSelection)}
							>
								Start your 14 day free trial
							</Button>
						</>
					)}
				</DialogContent>
			</Dialog>
		</div>
	);
};
