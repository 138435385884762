import React, { useContext, useState } from 'react';
import { ProfileSelectContext } from './ProfileSelectContext';
import { FacebookContext } from 'src/packages/facebook';
import { InstagramContext } from 'src/packages/instagram';
import { LinkedinContext } from 'src/packages/linkedin';

export const ProfileSelectProvider: React.FC = ({ children }) => {
	const { facebookPages } = useContext(FacebookContext);
	const { instagramPages } = useContext(InstagramContext);
	const { linkedInPages } = useContext(LinkedinContext);

	const allConnectedPages = [
		facebookPages ? [...facebookPages] : [],
		instagramPages ? [...instagramPages] : [],
		linkedInPages ? [...linkedInPages] : []
	].flat();

	const [selectedProfile, setSelectedProfile] = useState(allConnectedPages[0]);

	return (
		<ProfileSelectContext.Provider
			value={{ selectedProfile, setSelectedProfile }}
		>
			{children}
		</ProfileSelectContext.Provider>
	);
};
