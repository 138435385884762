import { Box, Card, Grid, CardActionArea } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { PricingPlan } from './PricingPlan';
import { stripeKeys } from 'src/config';

const useStyles = makeStyles({
	root: {
		maxWidth: 345
	},
	media: {
		height: 200
	},
	modalTitle: {
		paddingBottom: '10px'
	},
	tile: {
		position: 'relative'
	},
	tileTitle: {
		position: 'absolute',
		color: 'black',
		backgroundColor: 'rgba(255, 255, 255, 0.9)',
		padding: '4%',
		bottom: '0',
		textAlign: 'center',
		width: '100%',
		pointerEvents: 'none'
	}
});

const PricingOptions = ({ pricingSelection, setPricingSelection }) => {
	const classes = useStyles();
	const theme = useTheme();

	return (
		<Box
			sx={{
				backgroundColor: 'background.paper',
				p: 3
			}}
		>
			<Grid container spacing={3}>
				<Grid item xs={12} md={6} lg={6}>
					<Card
						sx={{
							boxSizing: 'border-box',
							outline:
								pricingSelection === stripeKeys.monthlyPriceId
									? `3px solid ${theme.palette.primary.main}`
									: '0'
						}}
						onClick={() => {
							setPricingSelection(stripeKeys.monthlyPriceId);
						}}
					>
						<CardActionArea>
							<PricingPlan
								cta="Sign Up"
								billing="/mo"
								currency="$"
								description="No contracts, cancel anytime."
								features={[
									'All previous',
									'Highlights reporting',
									'Data history',
									'Unlimited users'
								]}
								image="/logo-icon-tan.svg"
								name="monthly"
								popular
								stripePriceId={stripeKeys.monthlyPriceId}
								price="25.00"
								sx={{
									height: '100%',
									maxWidth: 460,
									mx: 'auto'
								}}
							/>
						</CardActionArea>
					</Card>
				</Grid>
				<Grid item xs={12} md={6} lg={6}>
					<Card
						sx={{
							boxSizing: 'border-box',
							outline:
								pricingSelection === stripeKeys.yearlyPriceId
									? `3px solid ${theme.palette.primary.main}`
									: '0'
						}}
						onClick={() => {
							setPricingSelection(stripeKeys.yearlyPriceId);
						}}
					>
						<CardActionArea>
							<PricingPlan
								cta="Sign Up"
								billing="/yr"
								currency="$"
								popular
								stripePriceId={stripeKeys.yearlyPriceId}
								description="Save 25% when paid annually."
								features={[
									'All previous',
									'Unlimited contacts',
									'Analytics platform',
									'Public API access',
									'Send and sign unlimited contracts'
								]}
								image="/icon-logo-blue.svg"
								name="yearly"
								price="225.00"
								sx={{
									height: '100%',
									maxWidth: 480,
									mx: 'auto'
								}}
							/>
						</CardActionArea>
					</Card>
				</Grid>
			</Grid>
		</Box>
	);
};

export default PricingOptions;
