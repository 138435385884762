import { API } from '../../lib/api/api';
import axios from 'axios';

export const updateExistingCustomer = async (
	stripePriceId: string,
	user: any,
	stripeCustomerId: string
) => {
	try {
		return await axios.post(
			`${API}stripe/createCheckoutSession/updateExistingCustomer`,
			{
				user: user,
				stripePriceId: stripePriceId,
				stripeCustomerId: stripeCustomerId
			}
		);
	} catch (e) {
		return {
			data: e,
			status: 'error'
		};
	}
};
