import axios from 'axios';
import { API } from 'src/packages/lib/api/api';

export const getAffiliateDetails = async (referralCode: string) => {
	try {
		return await axios.get(
			`${API}tapfiliate/getAffiliateDetails?referralCode=${referralCode}`
		);
	} catch (e) {
		return {
			data: e,
			error: e,
			status: 'error',
			statusText: 'There was a problem getting the affiliate details.'
		};
	}
};
