import { API } from '../../lib/api/api';
import axios from 'axios';

export const createCheckoutSession = async (
	stripePriceId: string,
	user: any,
	isAffiliateReferral: any,
	affiliateId?: any
) => {
	try {
		return await axios.post(`${API}stripe/createCheckoutSession/${user.sub}`, {
			user: user,
			stripePriceId: stripePriceId,
			isAffiliateReferral,
			affiliateId
		});
	} catch (e) {
		return {
			data: e,
			status: 'error'
		};
	}
};
