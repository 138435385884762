import { createContext } from 'react';

export interface MediaContainerContext {
	isMediaContainerOpen?: boolean;
	setIsMediaContainerOpen?: (arg: boolean) => void;
	publishMediaView?: boolean;
	togglePublishMediaView?: (arg: boolean) => void;
	previewMediaView?: boolean;
	togglePreviewMediaView?: (arg: boolean) => void;
}

export const MediaContainerContext = createContext<MediaContainerContext>(null);
