import { State } from './types';
import create from 'zustand';
import { updateUserSavedSocial, getUser } from '../../../../services/auth0';

export const useSavedSocialStore = create<State>((set, get) => ({
	savedSocial: [],
	initialSavedPosts: [],
	savedPostsPayload: [],
	isUpdatingSavedPosts: false,
	setInitialSavedPosts: async (userDetails) => {
		const initialSavedPosts = userDetails?.user_metadata?.savedSocial || [];
		return set((state) => {
			return {
				...state,
				initialSavedPosts,
				savedPostsPayload: initialSavedPosts
			};
		});
	},
	setSavedPostsPayload: (postId, remove?) => {
		const payload = get().savedPostsPayload;

		if (remove) {
			const newPayload = payload.filter((id) => id !== postId);
			return set((state) => {
				return {
					...state,
					savedPostsPayload: newPayload
				};
			});
		}

		if (payload.includes(postId)) {
			return;
		}

		const newPayload = [...payload, postId];

		set((state) => {
			return {
				...state,
				savedPostsPayload: newPayload
			};
		});
	},
	handleSavedPostsUpdate: async (userDetails, savedSocialPayload: []) => {
		set({ isUpdatingSavedPosts: true });
		const res = await updateUserSavedSocial(userDetails, savedSocialPayload);
		set((state) => {
			return {
				...state,
				initialSavedPosts: res.data.body
			};
		});
		set({ isUpdatingSavedPosts: false });
	}
}));
