// should return free trial or premium
import { useContext } from 'react';
import { PaywallContext } from 'src/packages/paywall';
import { stripeKeys } from '../../config';

export const useSubscriptionPlanDetails = () => {
	const { userSubscriptionDetailsCopy } = useContext(PaywallContext);

	if (!userSubscriptionDetailsCopy) {
		return {
			description: ''
		};
	}

	const name =
		userSubscriptionDetailsCopy?.status === 'trialing' ? 'Free trial' : 'Premium';
	const price =
		userSubscriptionDetailsCopy?.plan?.id === stripeKeys?.monthlyPriceId
			? '25.00'
			: '225.00';
	const cadence =
		userSubscriptionDetailsCopy?.plan?.id === stripeKeys?.monthlyPriceId
			? '/mo'
			: '/yr';
	const status = userSubscriptionDetailsCopy?.status;
	const description =
		userSubscriptionDetailsCopy?.status === 'trialing'
			? 'Free trial'
			: userSubscriptionDetailsCopy?.status === 'canceled'
			? ''
			: `Premium, $${price}${cadence}`;

	const hasUsedFreeTrial = userSubscriptionDetailsCopy?.status === 'canceled';

	return {
		currency: '$',
		price,
		name,
		cadence,
		status,
		description,
		hasUsedFreeTrial,
		customerId: userSubscriptionDetailsCopy?.customer
	};
};
