import { gql } from '@apollo/client';

export const getDemoLinks = gql`
	query GetDEMOLinks($names: [String]) {
		categories(where: { name: $names }) {
			edges {
				node {
					id
					posts {
						nodes {
							demoLinks {
								matchingstories {
									demoMatchingMaverick
									demoMatchingModern
									demoMatchingMoody
								}
								monthlydownloads {
									demoMonthlyMaverick
									demoMonthlyModern
									demoMonthlyMoody
								}
							}
						}
					}
				}
			}
		}
	}
`;
