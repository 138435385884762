import axios from 'axios';
import { API } from '../../../lib/api/api';

export const generateInstagramContainerId = async (
	userId: string,
	accessToken: string,
	url: string,
	caption: string
) => {
	try {
		const res = await axios.get(
			`${API}instagram/createPhotoContainer/${userId}?userId=${userId}&accessToken=${accessToken}&url=${url}&caption=${caption}`
		);
		return res?.data?.id;
	} catch (e) {
		return {
			error: e,
			status: 'error',
			statusText:
				'Oops! Your post was not sent. Try again, and please contact support if this issue persists.',
			data: e
		};
	}
};
