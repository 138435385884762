import { useContext } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';
import { MediaUploadContext } from 'src/packages/media-upload';

const useStyles = makeStyles((theme) => ({
	loadingContainer: {
		width: '500px',
		height: '500px',
		display: 'flex',
		marginRight: '2rem',
		[theme.breakpoints.down('md')]: {
			margin: '0 auto'
		}
	},
	innerLoadingContainer: {
		flex: 1,
		alignSelf: 'center',
		textAlign: 'center'
	},
	postImage: {
		width: '500px',
		height: '500px',
		[theme.breakpoints.down('md')]: {
			width: '100%',
			height: '100%'
		}
	}
}));

export const MediaLoader = () => {
	const styles = useStyles();
	const { customMediaUrl } = useContext(MediaUploadContext);

	return (
		<div className={styles.loadingContainer}>
			<div className={styles.innerLoadingContainer}>
				<CircularProgress />
			</div>
		</div>
	);
};
