import { createContext } from 'react';

interface ContentByCategoryContext {
	posts?: any[];
	setPosts?: any;
	localStorageSelections?: any;
	handleLocalStorageUpdate?: any;
	setCategories?: any;
	categories?: any;
	subCats?: string[];
	addCategory?: any;
	removeCategory?: any;
	tagSearch?: any;
	removeTaggedPosts?: any;
	multiSelectOptions?: any;
	isFetchingInitialPosts?: boolean;
}

export const ContentByCategoryContext =
	createContext<ContentByCategoryContext>(null);
