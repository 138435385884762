import axios from 'axios';
import { API } from '../../../lib/api/api';

export const postVideoToFacebook = async (
	pageId: string,
	accessToken: string,
	caption: string,
	photoUrl: string,
	auth0userId?: string,
	profileName?: string,
	publishTime?: Date,
	unpublished_post_id?: string
) => {
	try {
		if (publishTime) {
			return await axios.post(
				`${API}facebook/scheduleVideoPost?pageId=${pageId}&accessToken=${accessToken}&caption=${caption}&photoUrl=${photoUrl}&publishTime=${publishTime}&auth0userId=${auth0userId}&profileName=${profileName}&unpublished_post_id=${unpublished_post_id}`
			);
		} else {
			return await axios.post(
				`${API}facebook/videos/${pageId}?accessToken=${accessToken}&caption=${caption}&source=${photoUrl}`
			);
		}
	} catch (e) {
		return {
			error: e,
			status: 'error',
			statusText:
				'Something went wrong. Your post was not fulfulled. If this issue persists, contact support.'
		};
	}
};
