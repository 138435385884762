import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { auth0Config } from '../../config';
import { Auth0Provider } from '@auth0/auth0-react';

interface AuthProviderProps {
	children: ReactNode;
}

export const AuthProvider: FC<AuthProviderProps> = (props) => {
	const { children } = props;

	return (
		<Auth0Provider
			domain={auth0Config.domain}
			clientId={auth0Config.client_id}
			redirectUri={auth0Config.redirectUri}
			audience={`https://${auth0Config.audience}/api/v2/`}
			scope={
				'openid email profile read:current_user update:current_user_identities'
			}
			useRefreshTokens
			cacheLocation="localstorage"
		>
			{children}
		</Auth0Provider>
	);
};

AuthProvider.propTypes = {
	children: PropTypes.node.isRequired
};
