import { useState, useEffect, useContext } from 'react';
import type { FC, ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import { experimentalStyled } from '@material-ui/core/styles';
import { Alert, AlertTitle, Box } from '@mui/material';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import { FirstRunFlow } from '../firstRunFlow';
import { SubscriptionModal } from '../subscriptionModal';
import { useUserStore } from 'src/packages/state/store';
import {
	useSavedSocialStore,
	useDebounce
} from 'src/packages/lib/saved-social';
import { useQueryParameter } from 'src/packages/utils';

interface DashboardLayoutProps {
	children?: ReactNode;
}

const DashboardLayoutRoot = experimentalStyled('div')(({ theme }) => ({
	backgroundColor: theme.palette.background.default,
	display: 'flex',
	height: '100%',
	overflow: 'hidden',
	width: '100%'
}));

const DashboardLayoutWrapper = experimentalStyled('div')(({ theme }) => ({
	display: 'flex',
	flex: '1 1 auto',
	overflow: 'hidden',
	paddingTop: '64px',
	[theme.breakpoints.up('lg')]: {
		paddingLeft: '280px'
	}
}));

const DashboardLayoutContainer = experimentalStyled('div')({
	display: 'flex',
	flex: '1 1 auto',
	overflow: 'hidden'
});

const DashboardLayoutContent = experimentalStyled('div')({
	flex: '1 1 auto',
	height: '100%',
	overflow: 'auto',
	position: 'relative',
	WebkitOverflowScrolling: 'touch'
});

const DashboardLayout: FC<DashboardLayoutProps> = () => {
	const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState<boolean>(false);
	const [firstRunFlow, setFirstRunFlow] = useState<boolean>(false);
	const { userDetails } = useUserStore();
	const session_id = useQueryParameter('session_id');

	useEffect(() => {
		if (session_id) {
			window.tap('trial', userDetails?.user_metadata?.stripe?.customerId);
		}
	}, [session_id, userDetails]);

	useEffect(() => {
		if (userDetails && !userDetails?.user_metadata?.stylePreference) {
			setFirstRunFlow(true);
			return;
		}
	}, [userDetails]);
	const { savedPostsPayload, initialSavedPosts, handleSavedPostsUpdate } =
		useSavedSocialStore();
	const debouncedValue = useDebounce(savedPostsPayload, 2000);

	useEffect(() => {
		// this prevents the call from going out on page load
		if (debouncedValue === initialSavedPosts) {
			return;
		}
		userDetails && handleSavedPostsUpdate(userDetails, debouncedValue);
	}, [debouncedValue]);

	return (
		<>
			<DashboardLayoutRoot>
				<DashboardNavbar
					onSidebarMobileOpen={(): void => setIsSidebarMobileOpen(true)}
				/>
				{userDetails && (
					<DashboardSidebar
						onMobileClose={(): void => setIsSidebarMobileOpen(false)}
						openMobile={isSidebarMobileOpen}
					/>
				)}
				<DashboardLayoutWrapper>
					<DashboardLayoutContainer>
						<DashboardLayoutContent>
							{firstRunFlow && <FirstRunFlow />}
							{userDetails && <Outlet />}
						</DashboardLayoutContent>
					</DashboardLayoutContainer>
				</DashboardLayoutWrapper>
			</DashboardLayoutRoot>
		</>
	);
};

export default DashboardLayout;
