import { createContext } from 'react';

export interface MediaUploadContext {
	isUploadingMedia?: boolean;
	setIsUploadingMedia?: (arg: boolean) => void;
	setCustomMediaUrl?: (arg: string) => void;
	customMediaUrl?: string | boolean;
}

export const MediaUploadContext = createContext<MediaUploadContext>(null);
