import React, { useContext } from 'react';
import { useNavigate } from 'react-router';
import { Theme, withStyles } from '@mui/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import { PaywallContext } from '../context';

const DialogContent = withStyles((theme: Theme) => ({
	root: {
		padding: theme.spacing(2),
		textAlign: 'center'
	}
}))(MuiDialogContent);

export const PaymentPastDueDialog = () => {
	const { isPaymentPastDueDialogOpen, setIsPaymentPastDueDialogOpen } =
		useContext(PaywallContext);
	const navigate = useNavigate();
	return (
		<>
			<Dialog
				disableEscapeKeyDown
				aria-labelledby="payment-dialog"
				open={isPaymentPastDueDialogOpen}
				fullWidth={true}
				maxWidth={'md'}
				onClose={() => {
					setIsPaymentPastDueDialogOpen(false);
				}}
			>
				<DialogContent dividers>
					<div style={{ textAlign: 'center', marginBottom: '1rem' }}>
						<img src="/logoBlack.png" alt="logo" width="50%" />
					</div>
					<hr />
					<Typography mt="1rem" mb="1rem" textAlign="center" variant="h5">
						Gentle reminder: an invoice remains unpaid
					</Typography>
					<Typography mb="1rem" textAlign="center">
						Please visit your billing center to update payment methods, pay invoices
						and view your subscription details.
						<br />
						Thank you for choosing Copy and Post!
					</Typography>
					<Button
						onClick={() => {
							setIsPaymentPastDueDialogOpen(false);
							navigate('/dashboard/account/billing');
						}}
						size="large"
						variant="contained"
					>
						Click here to access billing center
					</Button>
				</DialogContent>
			</Dialog>
		</>
	);
};
