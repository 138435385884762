import { gql } from '@apollo/client';

/**
 * Gets all the child cateogories of a parent category
 * @param parentId: parent category slug. ie: 'content-by-categories'
 */
export const GET_CHILD_CATS_BY_PARENT_CAT_SLUG = gql`
	query GetChildCategoriesByParentId($parentId: ID!) {
		category(id: $parentId, idType: SLUG) {
			children {
				edges {
					node {
						id
						name
						slug
						children(first: 20) {
							nodes {
								name
								id
								slug
								databaseId
							}
						}
					}
				}
			}
		}
	}
`;
