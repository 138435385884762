import { createContext } from 'react';

interface NextMonthsMediaContext {
	posts?: any[];
	setPosts?: any;
	handleInitPosts?: any;
	localStorageSelections?: any;
	handleLocalStorageUpdate?: any;
	setCategories?: any;
	categories?: any;
	subCats?: string[];
	addCategory?: any;
	removeCategory?: any;
	tagSearch?: any;
	removeTaggedPosts?: any;
	multiSelectOptions?: any;
	isFetchingInitialPosts?: boolean;
}

export const NextMonthsMediaContext =
	createContext<NextMonthsMediaContext>(null);
