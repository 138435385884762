import { gql } from '@apollo/client';

const limitedContentQuery = gql`
        query GetPostsByTag($parentId: ID!, $tagSlugIn: [String]) {
        category(id: $parentId, idType: SLUG) {
            name
            slug
            posts(
                first: 500
                where: { tagSlugIn: $tagSlugIn, orderby: { field: DATE, order: DESC } }
            ) {
                edges {
                    node {
                        id
                        featuredPost {
                            suggestedpostdate
                            postimage {
                                mediaItemUrl
                                title
                            }
                        }
                        databaseId
                        tags {
                            edges {
                                node {
                                    name
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

const protectedContentQuery = gql`
    query GetPostsByTag($parentId: ID!, $tagSlugIn: [String]) {
        category(id: $parentId, idType: SLUG) {
            name
            slug
            posts(
                first: 500
                where: { tagSlugIn: $tagSlugIn, orderby: { field: DATE, order: DESC } }
            ) {
                edges {
                    node {
                        id
                        featuredPost {
                            canvalink
                            popularhashtags
                            recommendedcaption
                            suggestedpostdate
                            postimage {
                                mediaItemUrl
                                title
                            }
                        }
                        databaseId
                        tags {
                            edges {
                                node {
                                    name
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const getPostsByTagsNoChildren = (hasActiveSubscription) => {
    return hasActiveSubscription ? protectedContentQuery : limitedContentQuery;
}
