import React, { useState } from 'react';
import useClipboard from 'react-use-clipboard';
import { CustomCaptionsContext } from './CustomCaptionsContext';
import {
	cleanCaption,
	customizeCaption,
	prepareHashtags,
	prepareCaption
} from '../utils';
import { useCustomCaptions } from '../hooks';
import { useUserStore } from 'src/packages/state';

export const CustomCaptionsProvider: React.FC = ({ children }) => {
	const { userDetails } = useUserStore();
	const noCustomCaptionDetails = !userDetails?.user_metadata?.captionSettings;
	const [isCaptionCopied, setCaptionCopied] = useClipboard('');
	const [isHashtagsCopied, setHashtagsCopied] = useClipboard('');
	const [isBothCopied, setIsCaptionAndTagsCopied] = useClipboard('');
	const [regeneratedCaption, setRegeneratedCaption] = useState(false);

	return (
		<CustomCaptionsContext.Provider
			value={{
				noCustomCaptionDetails,
				cleanCaption,
				customizeCaption,
				setHashtagsCopied,
				setCaptionCopied,
				setIsCaptionAndTagsCopied,
				prepareCaption,
				prepareHashtags,
				useCustomCaptions,
				regeneratedCaption,
				setRegeneratedCaption
			}}
		>
			{children}
		</CustomCaptionsContext.Provider>
	);
};
