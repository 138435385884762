import { useContext } from 'react';
import {
	Select,
	MenuItem,
	FormHelperText,
	FormControl,
	InputLabel,
	TextField
} from '@mui/material';
import { ProfileSelectContext } from '../context/ProfileSelectContext';
import { FacebookContext } from 'src/packages/facebook';
import { InstagramContext } from 'src/packages/instagram';
import { LinkedinContext } from 'src/packages/linkedin';

const getPlatformLogo = (platform: string) => {
	switch (platform) {
		case 'linkedin':
			return 'https://manage.copyandpost.com/wp-content/uploads/2022/04/linkedin-240.svg';
			break;
		case 'instagram':
			return 'https://manage.copyandpost.com/wp-content/uploads/2022/01/instagram40x40.png';
			break;
		default:
			return 'https://manage.copyandpost.com/wp-content/uploads/2022/04/facebook-svg.svg';
			break;
	}
};

export const SocialProfileSelect = () => {
	const { facebookPages } = useContext(FacebookContext);
	const { instagramPages } = useContext(InstagramContext);
	const { linkedInPages } = useContext(LinkedinContext);
	const { setSelectedProfile, selectedProfile } =
		useContext(ProfileSelectContext);

	const handleChange = (event) => {
		setSelectedProfile(event.target.value);
	};

	return (
		<FormControl variant="standard" fullWidth>
			<InputLabel id="profile-selector">Publish To</InputLabel>
			<Select
				id="profile-selector"
				onChange={handleChange}
				value={selectedProfile}
			>
				{facebookPages?.map((page) => {
					return (
						<MenuItem value={page}>
							<img width="20px" height="20px" src={getPlatformLogo('facebook')} />
							<span style={{ marginLeft: '10px' }}>{page?.page_name}</span>
						</MenuItem>
					);
				})}
				{instagramPages?.map((page) => {
					return (
						<MenuItem value={page}>
							<img width="20px" height="20px" src={getPlatformLogo('instagram')} />
							<span style={{ marginLeft: '10px' }}>{page.username}</span>
						</MenuItem>
					);
				})}
				{linkedInPages?.map((page) => {
					return (
						<MenuItem value={page}>
							<img width="20px" height="20px" src={getPlatformLogo('linkedin')} />
							<span style={{ marginLeft: '10px' }}>{page.name}</span>
						</MenuItem>
					);
				})}
			</Select>
			<FormHelperText sx={{ marginBottom: '1rem' }}>
				Select a profile
			</FormHelperText>
		</FormControl>
	);
};
