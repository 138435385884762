import unidecode from 'unidecode';

/**
 * Function to decode caption characters to ensure consistent posts across platforms
 * @param caption
 * @returns string
 */
export const cleanCaption = (caption: string) => {
	return unidecode(caption);
};
