import { Button } from '@mui/material';
import ChevronUp from 'src/packages/icons/ChevronUp';

export const BackToTop = () => {
	return (
		<Button
			startIcon={<ChevronUp fontSize="small" />}
			size="large"
			variant="contained"
			color="inherit"
			onClick={() => {
				document.getElementById('page-title')?.scrollIntoView({
					behavior: 'smooth'
				});
			}}
			sx={{
				bottom: 0,
				margin: (theme) => theme.spacing(4),
				position: 'fixed',
				right: 0,
				zIndex: (theme) => theme.zIndex.speedDial
			}}
		>
			Back To Top
		</Button>
	);
};
